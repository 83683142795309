import { Injectable } from '@angular/core';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/config';
import { Like } from '../../model/like.model';


@Injectable()
export class ApiLikeService extends AbstractApiService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public async getLikeCount(): Promise<Like> {
    return this.get<Like>(this.buildApiURL([Config.Api.Like]));
  }
}
