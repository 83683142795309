import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from 'src/app/config';
import { AuthService } from 'src/app/core/service/auth.service';
import { StorageService } from 'src/app/shared/service/storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private storageService: StorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.authService.isAuthenticated || req.url === Config.Api.Login) {
      return next.handle(req);
    }

    return next.handle(req.clone({headers: new HttpHeaders({
      Authorization: `Bearer ${this.authService.authentication.token}`,
    })}));
  }

}
