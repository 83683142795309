import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Config } from 'src/app/config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SearchService extends AbstractApiService {
    constructor(http) {
        super(http);
        this.http = http;
    }
    getTags() {
        return this.get(this.buildApiURL(Config.Api.Tag));
    }
    getSystemCategories() {
        return this.get(this.buildApiURL(Config.Api.Category));
    }
    getAttributes() {
        return this.get(this.buildApiURL(Config.Api.Attribute));
    }
    getActivityRanges() {
        return this.get(this.buildApiURL(Config.Api.ActivityRange));
    }
    postCondition(tag) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = `${environment.eportfolio.api}${Config.Api.Tag}`;
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
            });
            const response = yield this.http.post(url, tag, { headers }).pipe(catchError(this.handleError('Tag request'))).toPromise();
            return response;
        });
    }
}
SearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchService_Factory() { return new SearchService(i0.ɵɵinject(i1.HttpClient)); }, token: SearchService, providedIn: "root" });
