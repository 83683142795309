import { Injectable } from '@angular/core';
import { ArrangementType } from 'src/app/app-const';
import { Utility } from 'src/app/shared/class/utility';
import { Config } from 'src/app/config';
import { ApiActivityService, ActivityPaginator } from 'src/app/shared/service/api/api-activity.service';
import { StorageService } from 'src/app/shared/service/storage.service';
import { Attachment } from 'src/app/core/model/attachment.model';
import { Activity, Tag, SystemCategory, SearchParams } from 'src/app/shared/model/activity.model';
import { isRoleStudent, User } from 'src/app/core/model/user.model';

@Injectable()
export class ActivityService {

  constructor(private apiActivityService: ApiActivityService, private storageService: StorageService) { }

  public getCurrentArrangementType(): ArrangementType {
    const user = this.storageService.getLoginUser().value;
    const storage = Utility.getStorageItem(Config.StorageKey.ArrangementType);
    if (storage === null) {
      const initialArrangementType = { type: ArrangementType.OneColumn };
      Utility.setStorageItem(Config.StorageKey.ArrangementType, initialArrangementType);
      return initialArrangementType.type;
    } else if (isRoleStudent(user.roles) && storage.type === ArrangementType.List) {
      return ArrangementType.OneColumn;
    }

    return storage.type;
  }

  public updateArrangementType(type: ArrangementType): void {
    const typeObj: { [key: string]: ArrangementType; } = { type };
    Utility.setStorageItem(Config.StorageKey.ArrangementType, typeObj);
  }

  public create(): Activity {
    return {
      user: {} as User,
      system_category: {} as SystemCategory,
      attachments: [] as Attachment[],
      tags: [] as Tag[],
      like_users: [] as User[],
    } as Activity;
  }

  public getActivity(id: number): Promise<Activity> {
    return this.apiActivityService.getActivity(id);
  }

  public getActivities(page: number = 1, params = {} as SearchParams): Promise<ActivityPaginator> {
    return this.apiActivityService.getActivities(page, params);
  }

  public async createActivity(activity: Activity | FormData): Promise<Activity> {
    return this.apiActivityService.createActivity(activity);
  }

  public async updateActivity(activity: Activity | FormData): Promise<Activity> {
    return this.apiActivityService.updateActivity(activity);
  }

  public async deleteActivity(id: number): Promise<boolean> {
    return this.apiActivityService.deleteActivity(id);
  }

  public async deleteAttachment(activityId: number, attachmentId: number): Promise<boolean> {
    return this.apiActivityService.deleteAttachment(activityId, attachmentId);
  }

  public async getTags(): Promise<Tag[]> {
    return this.apiActivityService.getTags();
    // return res ? res : {tags: [], self: []};
  }

  public async getSystemCategories(): Promise<SystemCategory[]> {
    const res = await this.apiActivityService.getSystemCategories();

    return res ? res : [];
  }

  public async likeActivity(activity: Activity): Promise<Activity> {
    return this.apiActivityService.likeActivity(activity);
  }

}
