import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';
import { LayoutService } from '../core/service/layout.service';
import { StorageService } from 'src/app/shared/service/storage.service';
import { SettingService } from 'src/app/shared/service/setting.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Utility } from 'src/app/shared/class/utility';
import { Config } from 'src/app/config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  message: string;

  loginForm = new FormGroup({
    loginId: new FormControl(),
    password: new FormControl(),
    remember: new FormControl(),
  });

  constructor(
    private title: Title,
    private router: Router,
    private authService: AuthService,
    private layoutService: LayoutService,
    private storageService: StorageService,
    private settingService: SettingService) {
    this.title.setTitle(`学びの記録 ログイン`);
    const user = this.storageService.getLoginUser().value;

    // 遷移元のサービスをクリア
    const serviceType = { service: '' };
    Utility.setStorageItem(Config.StorageKey.RelatedService, serviceType);

    if (user) {
      this.loginForm.setValue({loginId: user.username, password: '', remember: ''});
    }
  }

  ngOnInit() {
    this.authService.signout();
    this.message = '';
  }

  async onSubmit() {

    const value = this.loginForm.value;

    this.message = '';
    const success = await this.authService.signin(value.loginId, value.password);
    if (!success) {
      this.message = 'IDまたはパスワードに間違いがあります。';
      return;
    }
    this.router.navigate(['/']);
    this.layoutService.showNavigation();
    this.layoutService.showHeader();
    this.layoutService.showFixedBottomButton();

    const user = this.storageService.getLoginUser().value;
    this.settingService.updateScoutScopeString(user.setting.scout_scope);
  }
}
