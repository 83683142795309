<cdk-virtual-scroll-viewport class="viewport" itemSize="40" (scrolledIndexChange)="scrolledChange()">
  <div class="header">
    <mat-icon>notifications</mat-icon>
    <div class="header__text">お知らせ</div>
  </div>
  <ul class="list">
    <li class="list__elm" *cdkVirtualFor="let notice of notices">
      <div class="image">
        <img [attr.src]="notice.source_user | profileImage | trustUrl | async" alt="">
      </div>
      <div class="desc">
        <span [ngSwitch]="notice.notice_type">
          <span *ngSwitchCase="'like'">
            {{ notice.source_user.last_name }} {{ notice.source_user.first_name }}さんがあなたの学びの記録をいいねしました。
          </span>
          <span *ngSwitchCase="'follow'">
            {{ notice.source_user.last_name }} {{ notice.source_user.first_name }}さんがあなたをフォローしました。
          </span>
        </span>
        <span class="date">{{ notice.created_at | fromDate }}</span>
      </div>
    </li>
  </ul>
</cdk-virtual-scroll-viewport>