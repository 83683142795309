import * as tslib_1 from "tslib";
import { Config } from 'src/app/config';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
export class ApiSettingService extends AbstractApiService {
    constructor(http) {
        super(http);
        this.http = http;
    }
    getSettingResponse() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.get(this.buildApiURL([Config.Api.Setting]), this.transformSettingResponse);
        });
    }
    updateNoticeSetting(setting) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.put(this.buildApiURL([Config.Api.SettingNotice]), setting, this.transformSetting);
        });
    }
    updateSocialSetting(setting) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.put(this.buildApiURL([Config.Api.SettingSocial]), setting, this.transformSetting);
        });
    }
    transformSettingResponse(settingResponse) {
        return settingResponse;
    }
    transformSetting(setting) {
        return setting;
    }
}
