export class Config {
  public static ApiBase = '/api';
  public static Api = {
    RelatedServiceAuth: `/related_service/login_check`,
    Login: `${Config.ApiBase}/login_check`,
    Logout: `${Config.ApiBase}/logout`,
    User: `${Config.ApiBase}/user`,
    UserMe: `${Config.ApiBase}/user/me`,
    Like: `${Config.ApiBase}/user/likes`,
    Follow: `${Config.ApiBase}/user/follow`,
    Follower: `${Config.ApiBase}/user/follower`,
    Tag: `${Config.ApiBase}/activity/tags`,
    Attribute: `${Config.ApiBase}/attribute`,
    Category: `${Config.ApiBase}/system_category`,
    Activity: `${Config.ApiBase}/activity`,
    SystemCategory: `${Config.ApiBase}/activity/categories`,
    ActivityRange: `${Config.ApiBase}/activity/ranges`,
    ActivityTag: `${Config.ApiBase}/activity/activity_tags`,
    Setting: `${Config.ApiBase}/setting`,
    SettingNotice: `${Config.ApiBase}/setting/notice`,
    SettingSocial: `${Config.ApiBase}/setting/social`,
    ConfirmSheet: `${Config.ApiBase}/confirm_sheet`,
    Goal: `${Config.ApiBase}/profile`,
    AllActivity: `${Config.ApiBase}/all_activity`,
    StudentList: `${Config.ApiBase}/student_list`,
    Achievement: `${Config.ApiBase}/achievement`,
    Aspiration: `${Config.ApiBase}/schoolaspiration`,
    Notice: `${Config.ApiBase}/notice`,
    School: `${Config.ApiBase}/school`,
    ExportActivity: `${Config.ApiBase}/export/activity`,
  };
  public static StorageKey = {
    Auth: 'auth',
    ArrangementType: 'arrangementType',
    ScoutScope: 'scoutScope',
    RelatedService: 'relatedService',
    Menu: 'menu'
  };
}
