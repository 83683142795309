import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoticeService } from '../../service/notice.service';
import { Notice, Readed } from '../../model/notice.model';
import { Paginator } from '../../model/paginator.model';
import { Utility } from '../../class/utility';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-notice-dialog',
  templateUrl: './notice-dialog.component.html',
  styleUrls: ['./notice-dialog.component.scss']
})
export class NoticeDialogComponent implements OnInit {

  notices: Notice[];
  paginator: Paginator;
  isLoading: boolean;
  @ViewChild(CdkVirtualScrollViewport, {static: false}) viewport: CdkVirtualScrollViewport;

  constructor(
    public dialogRef: MatDialogRef<NoticeDialogComponent>,
    public noticeService: NoticeService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  async ngOnInit() {
    await this.initialProcess();
  }

  async initialProcess(): Promise<void> {
    this.notices = [];
    this.paginator = Utility.createPaginator();
    this.isLoading = false;
    await this.loadNextPageActivity();
  }

  async loadNextPageActivity(): Promise<void> {
    if (this.isLoading || (this.paginator.current_page > 0 && !this.paginator.next_page)) {
      return;
    }
    this.isLoading = true;
    try {
      const res = await this.noticeService.getNotices(++this.paginator.current_page);
      const readed = {} as Readed;
      const readedIdList = [];
      res.notices.forEach(r => readedIdList.push(r.id));
      readed.readed_ids = readedIdList;
      this.noticeService.updateToRead(readed);

      this.paginator = res.paginator;
      this.notices = this.notices.concat(res.notices);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  scrolledChange() {
    const remnantRender = this.notices.length - this.viewport.getRenderedRange().end;
    if (remnantRender < 10) {
      this.loadNextPageActivity();
    }
  }

}
