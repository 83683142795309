import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
export class TagDialogComponent {
    constructor(dialogRef, tagService, storageService, data) {
        this.dialogRef = dialogRef;
        this.tagService = tagService;
        this.storageService = storageService;
        this.data = data;
        this.tag = {};
        this.tags = [];
        this.user = null;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.user = this.storageService.getLoginUser().value;
            this.tags = yield this.tagService.getTags();
        });
    }
    editClicked(tag) {
        this.tag = tag;
    }
    deleteClicked(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm('削除しますか？')) {
                if (yield this.tagService.deleteTag(id)) {
                    this.tags = this.tags.filter(t => t.id !== id);
                    this.tag = {};
                }
            }
        });
    }
    dismissDialog() {
        this.dialogRef.close();
    }
    enableRegister() {
        if (this.tag.name && this.tag.name.length > 0) {
            return false;
        }
        return true;
    }
    onSubmit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.tag.id) {
                    const tag = yield this.tagService.putTag(this.tag);
                    const idx = tag ? this.tags.findIndex(t => t.id === tag.id) : -1;
                    if (idx >= 0) {
                        this.tags[idx] = tag;
                    }
                }
                else {
                    const tag = yield this.tagService.postTag(this.tag);
                    if (tag) {
                        tag.tag_count = 0;
                        tag.editable = true;
                        this.tags.unshift(tag);
                    }
                }
                this.errorMessage = '';
                this.tag = {};
            }
            catch (e) {
                console.log(e);
                this.errorMessage = e.message;
            }
        });
    }
}
