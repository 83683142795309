import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { map, catchError } from 'rxjs/operators';
import { OperatorFunction, of } from 'rxjs';

@Injectable()
export class UtilityService {

  constructor(private http: HttpClient) { }

  public async fileDownload(url: string, filename: string): Promise<boolean> {
    return await this.http.get(url, {responseType: 'blob'}).pipe(
      map(async (value) => {
        FileSaver.saveAs(value, filename);
        return true;
      }),
      catchError(this.handleError<boolean>(`Download ${url}`, false)),
    ).toPromise();
  }

  private handleError<T>(operation = 'operation', result: T = null): OperatorFunction<T, T> {
    return (error: any) => {
      console.error(error);
      console.log(`${operation} failed: ${error && error.message ? error.message : ''}`);

      return of(result);
    };
  }

}
