import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fromDate'
})
export class FromDatePipe implements PipeTransform {

  transform(date: number): string {
    moment.locale('ja');
    return moment(date).fromNow();
  }

}
