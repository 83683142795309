import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/core/service/layout.service';
import { StorageService } from '../../service/storage.service';
import { User, isRoleStudent, isRoleTeacher, Role } from 'src/app/core/model/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fixed-button',
  templateUrl: './fixed-button.component.html',
  styleUrls: ['./fixed-button.component.scss']
})
export class FixedButtonComponent implements OnInit {

  user: User;
  Role: typeof Role = Role;

  constructor(
    private layoutService: LayoutService,
    private storageService: StorageService,
    private router: Router
  ) {
    this.user = {} as User;
  }

  ngOnInit() {
    this.storageService.getLoginUser().observer.subscribe(async (value) => {
      this.user = value;
      if (this.user) {
        this.initialProcess();
      }
    });
  }

  initialProcess(): void {

  }

  visibleLabel(role: Role): boolean {
    if (!this.user.roles) { return; }
    if (role === Role.Student) {
      return isRoleStudent(this.user.roles);
    }
    if (role === Role.Teacher) {
      return isRoleTeacher(this.user.roles);
    }
    return false;
  }

  clickButton(): void {
    if (isRoleStudent(this.user.roles)) {
      this.router.navigate(['/activity/upsert']);
      return;
    }
    if (isRoleTeacher(this.user.roles)) {
      this.router.navigate(['/student']);
      return;
    }
  }

  get visible(): boolean {
    return this.layoutService.visibleFixedBottomButton;
  }

}
