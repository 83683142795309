<mat-drawer-container class="drawer-container" autosize>
	<mat-drawer #drawer class="drawer-sidenav" mode="side">
		<app-navigation></app-navigation>
	</mat-drawer>
	<div class="container">
		<router-outlet></router-outlet>
	</div>
</mat-drawer-container>
<app-fixed-button class="fixed-button"
	[ngClass]="{'fixed-button__hide': isOpenedSideMenu()}"
></app-fixed-button>