import { Injectable } from '@angular/core';
import { Tag, SearchParams } from 'src/app/core/model/tag.model';
import { ApiTagService, ActivityTagResponse } from './api/api-tag.service';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(private apiTagService: ApiTagService) {
  }

  public getTags(): Promise<Tag[]> {
    return this.apiTagService.getTags();
  }

  public async postTag(tag: Tag): Promise<Tag> {
    return this.apiTagService.postTag(tag);
  }

  public async putTag(tag: Tag): Promise<Tag> {
    return this.apiTagService.putTag(tag);
  }

  public async deleteTag(id: number): Promise<boolean> {
    return this.apiTagService.deleteTag(id);
  }

  public getActivityTags(page: number = 1, params = {} as SearchParams): Promise<ActivityTagResponse> {
    return this.apiTagService.getActivityTags(page, params);
  }
}
