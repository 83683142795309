import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/core/model/user.model';

@Pipe({
  name: 'username'
})
export class FormatUserNamePipe implements PipeTransform {

  /**
   * User object to format user name string.
   * @param value User object.
   * @param format format type string.
   *
   * format:
   *   full      <gakka> <grade> <class> <last_name> <first_name>
   *   username  <last_name> <first_name>
   *   lastname  <last_name>
   *   firstname <first_name>
   */
  transform(value: User | null, format: string = 'full'): any {
    if (value === null || value === undefined) {
      return '';
    }
    if (format === 'lastname') {
      return value.last_name;
    }
    if (format === 'firstname') {
      return value.first_name;
    }
    if (format === 'username') {
      return `${value.last_name} ${value.first_name}`;
    }

    const res: string[] = [];
    if (Array.isArray(value.attributes)) {
      const attrNames = {
        courses: [],
        subjects: [],
        grades: [],
        classes: [],
      };
      for (const attr of value.attributes) {
        switch (attr.division) {
          case 'ATTR_GAKKA':
            attrNames.courses.push(attr.name);
            break;
          case 'ATTR_KAMOKU':
            attrNames.subjects.push(attr.name);
            break;
          case 'ATTR_GRADE':
            attrNames.grades.push(attr.name);
            break;
          case 'ATTR_CLASS':
            attrNames.classes.push(attr.name);
            break;
        }
      }
      res.push(... attrNames.courses);
      res.push(... attrNames.subjects);
      res.push(... attrNames.grades);
      res.push(... attrNames.classes);
    }
    if (format !== 'short') {
      res.push(value.last_name);
      res.push(value.first_name);
    }

    return res.join(' ');
  }

}
