import { Injectable } from '@angular/core';
import {
  ApiAspirationService, AspirationResponse, AspirationResultsResponse,
  AspirationResultResponse, AspirationCandidateResponse
} from 'src/app/shared/service/api/api-aspiration.service';
import { Aspiration } from 'src/app/shared/model/aspiration.model';

@Injectable()
export class AspirationService {

  constructor(private apiAspirationService: ApiAspirationService) { }

  public getCandidates(): Promise<AspirationCandidateResponse> {
    return this.apiAspirationService.getCandidates();
  }

  public getAspirations(): Promise<AspirationResponse> {
    return this.apiAspirationService.getAspirations();
  }

  public getAspirationResults(userId: number = null): Promise<AspirationResultsResponse> {
    return this.apiAspirationService.getAspirationResults(userId);
  }

  public getAspirationResult(aspirationId: number = null): Promise<AspirationResultResponse> {
    return this.apiAspirationService.getAspirationResult(aspirationId);
  }

  public async updateResult(aspirationId: number, aspirationJson: any): Promise<Aspiration> {
    return this.apiAspirationService.updateResult(aspirationId, aspirationJson);
  }
}
