import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, OperatorFunction, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Config } from 'src/app/config';
import { Authentication } from 'src/app/core/model/authentication.model';
import { RelatedServiceAuth } from '../model/related-service-auth.model';
import { UserService } from 'src/app/core/service/user.service';
import { StorageService } from 'src/app/shared/service/storage.service';
import { Utility } from 'src/app/shared/class/utility';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private auth: Authentication;

  constructor(private http: HttpClient, private storageService: StorageService, private userService: UserService) {
    this.auth = null;
  }

  public async signin(username: string, password: string): Promise<boolean> {
    const url = `${environment.eportfolio.api}${Config.Api.Login}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = {
      username,
      password,
    };

    const auth = await this.http.post<Authentication>(url, body, {headers}).pipe(
      catchError(this.handleError<Authentication>('Authentication request')),
    ).toPromise();
    if (auth === null) {
      return false;
    }
    Utility.setStorageItem(Config.StorageKey.Auth, auth);
    this.auth = auth;

    const user = await this.userService.getMe();
    if (user === null) {
      return false;
    }
    this.storageService.getLoginUser().onChanged(user);

    return true;
  }

  public signout(): Observable<boolean> {
    this.auth = null;
    this.storageService.getLoginUser().onChanged(null);
    Utility.removeStorageItem(Config.StorageKey.Auth);
    // Utility.removeStorageItem(Config.StorageKey.ArrangementType);

    return new Observable<boolean>((s) => { s.next(true); });
  }

  public async relatedServiceSignin(relatedServiceAuth: RelatedServiceAuth): Promise<boolean> {
    const url = `${environment.eportfolio.api}${Config.Api.RelatedServiceAuth}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const response = await this.http.post<Authentication>(url, relatedServiceAuth, {headers}).pipe(
      catchError(this.handleError<Authentication>('Authentication request')),
    ).toPromise();
    if (response === null) {
      return false;
    }

    const auth: Authentication = { token: response.token };
    Utility.setStorageItem(Config.StorageKey.Auth, auth);
    this.auth = auth;

    const user = await this.userService.getMe();
    if (user === null) {
      return false;
    }
    this.storageService.getLoginUser().onChanged(user);

    return true;
  }

  public get isAuthenticated(): boolean {
    if (this.auth !== null) {
      return true;
    }

    const storage = Utility.getStorageItem(Config.StorageKey.Auth);
    if (storage === null) {
      return false;
    }
    this.auth = storage;

    return true;
  }

  public get authentication(): Readonly<Authentication> {
    if (this.isAuthenticated) {
      return this.auth;
    }
    return null;
  }

  private handleError<T>(operation = 'operation', result: T = null): OperatorFunction<T, T> {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }
}
