import * as tslib_1 from "tslib";
import { SettingScoutScope } from 'src/app/app-const';
import { Utility } from 'src/app/shared/class/utility';
import { Config } from 'src/app/config';
export class SettingService {
    constructor(apiSettingService) {
        this.apiSettingService = apiSettingService;
    }
    getScoutScope() {
        const storage = Utility.getStorageItem(Config.StorageKey.ScoutScope);
        if (storage === null) {
            const initialScoutScopeType = { type: SettingScoutScope.Disable };
            Utility.setStorageItem(Config.StorageKey.ScoutScope, initialScoutScopeType);
            return initialScoutScopeType.type;
        }
        return storage.type;
    }
    updateScoutScope(type) {
        const typeObj = { type };
        Utility.setStorageItem(Config.StorageKey.ScoutScope, typeObj);
    }
    updateScoutScopeString(type) {
        let typeObj = SettingScoutScope.Disable;
        if (type === SettingScoutScope.Each) {
            typeObj = SettingScoutScope.Each;
        }
        else if (type === SettingScoutScope.Unlimited) {
            typeObj = SettingScoutScope.Unlimited;
        }
        this.updateScoutScope(typeObj);
    }
    getSetting() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiSettingService.getSettingResponse();
        });
    }
    updateSocialSetting(setting) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiSettingService.updateSocialSetting(setting);
        });
    }
    updateNoticeSetting(setting) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiSettingService.updateNoticeSetting(setting);
        });
    }
}
