/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./grade-graph.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./grade-graph.component";
import * as i3 from "../../service/confirm-sheet.service";
var styles_GradeGraphComponent = [i0.styles];
var RenderType_GradeGraphComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GradeGraphComponent, data: {} });
export { RenderType_GradeGraphComponent as RenderType_GradeGraphComponent };
export function View_GradeGraphComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { canvas: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["canvas", 1]], null, 0, "canvas", [], null, null, null, null, null))], null, null); }
export function View_GradeGraphComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-grade-graph", [], null, null, null, View_GradeGraphComponent_0, RenderType_GradeGraphComponent)), i1.ɵdid(1, 4243456, null, 0, i2.GradeGraphComponent, [i3.ConfirmSheetService], null, null)], null, null); }
var GradeGraphComponentNgFactory = i1.ɵccf("app-grade-graph", i2.GradeGraphComponent, View_GradeGraphComponent_Host_0, { achievements: "achievements", mode: "mode" }, {}, []);
export { GradeGraphComponentNgFactory as GradeGraphComponentNgFactory };
