import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/core/model/user.model';

@Component({
  selector: 'app-user-belonging',
  templateUrl: './user-belonging.component.html',
  styleUrls: ['./user-belonging.component.scss']
})
export class UserBelongingComponent implements OnInit {

  @Input() user: User;
  fullStr: string;
  displayStr: string;
  enableWrap: boolean;
  visibleFullStr: boolean;

  constructor() {
    this.fullStr = '';
    this.displayStr = '';
    this.enableWrap = false;
    this.visibleFullStr = false;
  }

  ngOnInit() {
    this.initialProcess();
  }

  initialProcess(): void {
    this.fullStr = this.createBelongingStrObj().join(' ');
    this.enableWrap = this.fullStr.length > 50 ? true : false;
    this.displayStr = this.enableWrap ? `${this.fullStr.slice(0, 50)}…` : this.fullStr;
  }

  toggleWrap(): void {
    this.visibleFullStr = !this.visibleFullStr;
    this.displayStr = this.visibleFullStr ? this.fullStr : `${this.fullStr.slice(0, 50)}…`;
  }

  createBelongingStrObj(): string[] {
    const res: string[] = [];
    if (Array.isArray(this.user.attributes)) {
      const attrNames = {
        courses: [],
        subjects: [],
        grades: [],
        classes: [],
      };
      for (const attr of this.user.attributes) {
        switch (attr.division) {
          case 'ATTR_GAKKA':
            attrNames.courses.push(attr.name);
            break;
          case 'ATTR_KAMOKU':
            attrNames.subjects.push(attr.name);
            break;
          case 'ATTR_GRADE':
            attrNames.grades.push(attr.name);
            break;
          case 'ATTR_CLASS':
            attrNames.classes.push(attr.name);
            break;
        }
      }
      res.push(...attrNames.courses);
      res.push(...attrNames.subjects);
      res.push(...attrNames.grades);
      res.push(...attrNames.classes);
    }
    return res;
  }
}
