export enum ArrangementType {
  OneColumn = 'OneColumn',
  MultiColumn = 'MultiColumn',
  List = 'List',
}

export enum Navigation {
  Top,
  New,
  Grade,
  Aspiration,
  Goal,
  ConfirmSeat,
  Student,
  Teacher,
  AllActivities,
  Setting,
  Logout,
  ReturnService,
  School,
}

export enum ActivityTerm {
  Single = 'single',
  Range = 'range',
  Multiple = 'multiple',
}

export enum SortItem {
  Created = 'created',
  Grade = 'grade',
  Class = 'class',
  StudentNumber = 'student_number',
  Name = 'name',
  Category = 'category',
  Title = 'title',
  Attach = 'attach',
  Department = 'department',
  Activities = 'activities'
}

export const OrderBy = {
  Asc: 'a',
  Desc: 'd'
};

export const ApplicationModule = {
  Activity: 'activity',
  Profile: 'profile',
  UserSetting: 'user_setting',
  Achievement: 'achievement',
  SchoolAspiration: 'school_aspiration',
  LearningSituation: 'learning_situation',
  Attendance: 'attendance',
  Message: 'message',
  ConfirmSheet: 'confirm_sheet',
  Follow: 'follow',
  StudentList: 'student_list',
  ExportActivity: 'export_activity',
};

export const ApplicationMenu = {
  Student: {
    SideActivity: 'side_s_activity',
    SideAchievement: 'side_s_achievement',
    SideSchoolAspiration: 'side_s_school_aspiration',
    SideProfile: 'side_s_profile',
    SideConfirmSheet: 'side_s_confirm_sheet',
    SideUserSetting: 'side_s_user_setting',
    SubActivity: 'sub_s_activity',
    SubProfile: 'sub_s_profile',
    ViewFollow: 'view_s_follow',
    ViewSearchRange: 'view_s_search_range',
    ViewPublicUniversity: 'view_s_public_university',
  },
  Teacher: {
    SideAllActivity: 'side_t_all_activity',
    SideAllStudent: 'side_t_all_student',
    SideActivity: 'side_t_teacher_activity',
    SideUserSetting: 'side_t_user_setting',
    SubActivity: 'sub_t_activity',
    SubProfile: 'sub_t_profile',
    SubAchievement: 'sub_t_achievement',
    SubSchoolAspiration: 'sub_t_school_aspiration',
    SubConfirmSheet: 'sub_t_confirm_sheet',
    SubExportActivity: 'sub_t_export_activity',
    ViewFollow: 'view_t_follow',
    ViewPublicUniversity: 'view_t_public_university',
  },
  Recruit: {
    SideAllActivity: 'side_r_all_activity',
    SideAllStudent: 'side_r_all_student',
    SideActivity: 'side_r_teacher_activity',
    SideUserSetting: 'side_r_user_setting',
    SubActivity: 'sub_r_activity',
    SubProfile: 'sub_r_profile',
    SubAchievement: 'sub_r_achievement',
    SubSchoolAspiration: 'sub_r_school_aspiration',
    SubConfirmSheet: 'sub_r_confirm_sheet',
    ViewFollow: 'view_r_follow',
    ViewPublicUniversity: 'view_r_public_university',
  },
};

export const StudentMenu = [
  { item: Navigation.Top, icon: 'home', title: 'トップ', selected: false },
  { item: Navigation.New, icon: 'edit', title: '学びの記録入力', selected: false, name: ApplicationMenu.Student.SideActivity },
  { item: Navigation.Grade, icon: 'timeline', title: '成績', selected: false, name: ApplicationMenu.Student.SideAchievement },
  { item: Navigation.Aspiration, icon: 'school', title: '志望校', selected: false, name: ApplicationMenu.Student.SideSchoolAspiration },
  { item: Navigation.Goal, icon: 'stars', title: '目標設定', selected: false, name: ApplicationMenu.Student.SideProfile },
  { item: Navigation.ConfirmSeat, icon: 'note', title: '確認シート', selected: false, name: ApplicationMenu.Student.SideConfirmSheet },
  { item: Navigation.Setting, icon: 'settings_applications', title: '設定', selected: false, name: ApplicationMenu.Student.SideUserSetting },
  { item: Navigation.Logout, icon: 'exit_to_app', title: 'ログイン画面に戻る', selected: false },
  { item: Navigation.ReturnService, icon: 'exit_to_app', title: 'メインメニューへ', selected: false }
];

export const TeacherMenu = [
  { item: Navigation.AllActivities, icon: 'home', title: '全ての学びの記録', selected: false, name: ApplicationMenu.Teacher.SideAllActivity },
  { item: Navigation.Student, icon: 'accessibility', title: '生徒一覧', selected: false, name: ApplicationMenu.Teacher.SideAllStudent },
  { item: Navigation.Teacher, icon: 'settings_input_antenna', title: '先生から発信する', selected: false,
    name: ApplicationMenu.Teacher.SideActivity },
  { item: Navigation.Setting, icon: 'settings_applications', title: '設定', selected: false, name: ApplicationMenu.Teacher.SideUserSetting },
  { item: Navigation.Logout, icon: 'exit_to_app', title: 'ログイン画面に戻る', selected: false },
  { item: Navigation.ReturnService, icon: 'exit_to_app', title: 'メインメニューへ', selected: false }
];

export const RecruitMenu = [
  { item: Navigation.AllActivities, icon: 'home', title: '全ての学びの記録', selected: false, name: ApplicationMenu.Recruit.SideAllActivity },
  { item: Navigation.Student, icon: 'accessibility', title: '生徒一覧', selected: false, name: ApplicationMenu.Recruit.SideAllStudent },
  { item: Navigation.Setting, icon: 'settings_applications', title: '設定', selected: false, name: ApplicationMenu.Recruit.SideUserSetting },
  { item: Navigation.Logout, icon: 'exit_to_app', title: 'ログイン画面に戻る', selected: false },
  { item: Navigation.ReturnService, icon: 'exit_to_app', title: 'メインメニューへ', selected: false }
];

export const AdminMenu = [
  { item: Navigation.School, icon: 'apartment', title: '学校の管理・設定', selected: true },
  { item: Navigation.Logout, icon: 'exit_to_app', title: 'ログイン画面に戻る', selected: false },
];

export enum SettingScoutScope {
  Unlimited = 'unlimited',
  Each = 'each',
  Disable = 'disable',
}

export const ActivityTagType = [
  { item: 'mine', title: '自分のタグ' },
  { item: 'other', title: 'みんなのタグ' },
  { item: 'school', title: '学校のタグ' },
];
