import * as tslib_1 from "tslib";
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { Config } from 'src/app/config';
export class ApiLikeService extends AbstractApiService {
    constructor(http) {
        super(http);
        this.http = http;
    }
    getLikeCount() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.get(this.buildApiURL([Config.Api.Like]));
        });
    }
}
