import * as tslib_1 from "tslib";
import { COLOR_CODES } from '../model/confirm-sheet.model';
import * as i0 from "@angular/core";
import * as i1 from "./api/api-confirm-sheet.service";
export class ConfirmSheetService {
    constructor(apiConfirmSheetService) {
        this.apiConfirmSheetService = apiConfirmSheetService;
    }
    getColorCodeList(length) {
        return COLOR_CODES.slice(0, length);
    }
    getResumes(userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiConfirmSheetService.getResumes(userId);
        });
    }
    getAchievement(userId, resumeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiConfirmSheetService.getAchievement(userId, resumeId);
        });
    }
    getSheet(userId, resumeId) {
        return this.apiConfirmSheetService.getSheet(userId, resumeId);
    }
}
ConfirmSheetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfirmSheetService_Factory() { return new ConfirmSheetService(i0.ɵɵinject(i1.ApiConfirmSheetService)); }, token: ConfirmSheetService, providedIn: "root" });
