import * as tslib_1 from "tslib";
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { Config } from 'src/app/config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ApiNoticeService extends AbstractApiService {
    constructor(http) {
        super(http);
        this.http = http;
    }
    getNotices(page = 1) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.getWithPaging(this.buildApiURL(Config.Api.Notice), page);
        });
    }
    getUnreadCount() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.get(this.buildApiURL([Config.Api.Notice, 'unread', 'count']));
        });
    }
    updateToRead(readed) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.post(this.buildApiURL([Config.Api.Notice, 'readed']), readed);
        });
    }
}
ApiNoticeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiNoticeService_Factory() { return new ApiNoticeService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiNoticeService, providedIn: "root" });
