import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Tag } from 'src/app/core/model/tag.model';
import { TagService } from 'src/app/shared/service/tag.service';
import { User } from 'src/app/core/model/user.model';
import { StorageService } from '../../service/storage.service';

@Component({
  selector: 'app-tag-dialog',
  templateUrl: './tag-dialog.component.html',
  styleUrls: ['./tag-dialog.component.scss']
})
export class TagDialogComponent implements OnInit {

  tag: Tag;
  tags: Tag[];
  user: User;
  errorMessage: string;

  constructor(
    public dialogRef: MatDialogRef<TagDialogComponent>,
    private tagService: TagService,
    private storageService: StorageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tag = {} as Tag;
    this.tags = [] as Tag[];
    this.user = null;
  }

  async ngOnInit() {
    this.user = this.storageService.getLoginUser().value;
    this.tags = await this.tagService.getTags();
  }

  editClicked(tag: Tag) {
    this.tag = tag;
  }

  async deleteClicked(id: number) {
    if (confirm('削除しますか？')) {
      if (await this.tagService.deleteTag(id)) {
        this.tags = this.tags.filter(t => t.id !== id);
        this.tag = {} as Tag;
      }
    }
  }

  dismissDialog() {
    this.dialogRef.close();
  }

  enableRegister(): boolean {
    if (this.tag.name && this.tag.name.length > 0) {
      return false;
    }
    return true;
  }

  async onSubmit() {
    try {
      if (this.tag.id) {
        const tag = await this.tagService.putTag(this.tag);
        const idx = tag ? this.tags.findIndex(t => t.id === tag.id) : -1;
        if (idx >= 0) {
          this.tags[idx] = tag;
        }
      } else {
        const tag = await this.tagService.postTag(this.tag);
        if (tag) {
          tag.tag_count = 0;
          tag.editable = true;
          this.tags.unshift(tag);
        }
      }
      this.errorMessage = '';
      this.tag = {} as Tag;
    } catch (e) {
      console.log(e);
      this.errorMessage = e.message;
    }
  }
}
