import { Subject, Observable } from 'rxjs';

export class SubjectValue<T> {

  private localValue: T;
  private localSubject = new Subject<T>();
  private localValue$ = this.localSubject.asObservable();

  public onChanged(updated: T): void {
    this.localValue = updated;
    this.localSubject.next(updated);
  }

  get value(): Readonly<T> {
    return this.localValue;
  }

  get observer(): Observable<T> {
    return this.localValue$;
  }

}
