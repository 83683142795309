import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'trustUrl'
})
export class TrustUrlPipe implements PipeTransform {

  constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {}

  transform(url: string): Observable<SafeUrl> {
    return this.http.get(url, {responseType: 'blob'}).pipe(
      map(value => {
        return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(value));
      })
    );
  }

}
