import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Utility } from '../../class/utility';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
export class NoticeDialogComponent {
    constructor(dialogRef, noticeService, data) {
        this.dialogRef = dialogRef;
        this.noticeService = noticeService;
        this.data = data;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.initialProcess();
        });
    }
    initialProcess() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.notices = [];
            this.paginator = Utility.createPaginator();
            this.isLoading = false;
            yield this.loadNextPageActivity();
        });
    }
    loadNextPageActivity() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isLoading || (this.paginator.current_page > 0 && !this.paginator.next_page)) {
                return;
            }
            this.isLoading = true;
            try {
                const res = yield this.noticeService.getNotices(++this.paginator.current_page);
                const readed = {};
                const readedIdList = [];
                res.notices.forEach(r => readedIdList.push(r.id));
                readed.readed_ids = readedIdList;
                this.noticeService.updateToRead(readed);
                this.paginator = res.paginator;
                this.notices = this.notices.concat(res.notices);
            }
            catch (error) {
                console.log(error);
            }
            finally {
                this.isLoading = false;
            }
        });
    }
    scrolledChange() {
        const remnantRender = this.notices.length - this.viewport.getRenderedRange().end;
        if (remnantRender < 10) {
            this.loadNextPageActivity();
        }
    }
}
