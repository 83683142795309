import * as tslib_1 from "tslib";
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { Config } from 'src/app/config';
export class ApiUserService extends AbstractApiService {
    constructor(http) {
        super(http);
        this.http = http;
    }
    getMe() {
        return this.get(this.buildApiURL(Config.Api.UserMe));
    }
    getUser(id) {
        return this.get(this.buildApiURL([Config.Api.User, id]));
    }
    getFollows() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.gets(this.buildApiURL([Config.Api.Follow]));
        });
    }
    getFollowers() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.gets(this.buildApiURL([Config.Api.Follower]));
        });
    }
    addFollower(user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.putb(this.buildApiURL([Config.Api.User, user.id, 'follow']), user);
        });
    }
    removeFollower(user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.deleteb(this.buildApiURL([Config.Api.User, user.id, 'follow']));
        });
    }
    getFollowable(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.get(this.buildApiURL([Config.Api.User, id, 'followable']));
        });
    }
}
