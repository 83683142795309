import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Utility } from 'src/app/shared/class/utility';
import { Config } from 'src/app/config';
export class LoginComponent {
    constructor(title, router, authService, layoutService, storageService, settingService) {
        this.title = title;
        this.router = router;
        this.authService = authService;
        this.layoutService = layoutService;
        this.storageService = storageService;
        this.settingService = settingService;
        this.loginForm = new FormGroup({
            loginId: new FormControl(),
            password: new FormControl(),
            remember: new FormControl(),
        });
        this.title.setTitle(`学びの記録 ログイン`);
        const user = this.storageService.getLoginUser().value;
        // 遷移元のサービスをクリア
        const serviceType = { service: '' };
        Utility.setStorageItem(Config.StorageKey.RelatedService, serviceType);
        if (user) {
            this.loginForm.setValue({ loginId: user.username, password: '', remember: '' });
        }
    }
    ngOnInit() {
        this.authService.signout();
        this.message = '';
    }
    onSubmit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const value = this.loginForm.value;
            this.message = '';
            const success = yield this.authService.signin(value.loginId, value.password);
            if (!success) {
                this.message = 'IDまたはパスワードに間違いがあります。';
                return;
            }
            this.router.navigate(['/']);
            this.layoutService.showNavigation();
            this.layoutService.showHeader();
            this.layoutService.showFixedBottomButton();
            const user = this.storageService.getLoginUser().value;
            this.settingService.updateScoutScopeString(user.setting.scout_scope);
        });
    }
}
