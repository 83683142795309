import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "./api/api-tag.service";
export class TagService {
    constructor(apiTagService) {
        this.apiTagService = apiTagService;
    }
    getTags() {
        return this.apiTagService.getTags();
    }
    postTag(tag) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiTagService.postTag(tag);
        });
    }
    putTag(tag) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiTagService.putTag(tag);
        });
    }
    deleteTag(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiTagService.deleteTag(id);
        });
    }
    getActivityTags(page = 1, params = {}) {
        return this.apiTagService.getActivityTags(page, params);
    }
}
TagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TagService_Factory() { return new TagService(i0.ɵɵinject(i1.ApiTagService)); }, token: TagService, providedIn: "root" });
