<div class="container">
  <mat-card class="form">
    <div class="form__head">学びの記録</div>
    <mat-divider></mat-divider>
		<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="form__id">
        <mat-form-field class="form__field">
          <input matInput type="text" class="form__field__elm" formControlName="loginId" placeholder="IDを入力してください">
        </mat-form-field>
      </div>
      <div class="form__password">
        <mat-form-field class="form__field">
          <input matInput type="password" class="form__field__elm" formControlName="password" placeholder="パスワードを入力してください">
        </mat-form-field>
      </div>

      <div class="form__remember">
        <mat-checkbox formControlName="remember">ログイン状態を保持する</mat-checkbox>
      </div>

      <div class="form__button">
        <button mat-raised-button color="primary" type="submit">ログイン</button>
      </div>
    </form>

    <div class="form__message">
      {{ message }}
    </div>

    <div class="form__cantlogin">
      <a href="">ログインできない場合はこちら</a>
    </div>

    <div class="form__forgot">
      パスワードをお忘れの場合は直接学校・大学にお問い合わせください。
    </div>
  </mat-card>
</div>


