import * as tslib_1 from "tslib";
export class AspirationService {
    constructor(apiAspirationService) {
        this.apiAspirationService = apiAspirationService;
    }
    getCandidates() {
        return this.apiAspirationService.getCandidates();
    }
    getAspirations() {
        return this.apiAspirationService.getAspirations();
    }
    getAspirationResults(userId = null) {
        return this.apiAspirationService.getAspirationResults(userId);
    }
    getAspirationResult(aspirationId = null) {
        return this.apiAspirationService.getAspirationResult(aspirationId);
    }
    updateResult(aspirationId, aspirationJson) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiAspirationService.updateResult(aspirationId, aspirationJson);
        });
    }
}
