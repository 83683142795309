import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { Config } from 'src/app/config';
import { ConfirmSheet } from '../../model/confirm-sheet.model';

@Injectable({
  providedIn: 'root'
})
export class ApiConfirmSheetService extends AbstractApiService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public async getResumes(userId: number): Promise<any> {
    return this.get(this.buildApiURL([Config.Api.ConfirmSheet, 'resume', userId]));
  }

  public async getAchievement(userId: number, resumeId: number): Promise<any> {
    const arrayForBuildUrl = resumeId ?
      [Config.Api.ConfirmSheet, 'achievement', userId, 'resume', resumeId]
      : [Config.Api.ConfirmSheet, 'achievement', userId];
    return this.get(this.buildApiURL(arrayForBuildUrl));
  }

  public async getSheet(userId: number = null, resumeId: number = null): Promise<ConfirmSheet> {
    const arrayForBuildUrl = resumeId ?
      [Config.Api.ConfirmSheet, userId, 'resume', resumeId]
      : [Config.Api.ConfirmSheet, userId];
    return this.get(this.buildApiURL(arrayForBuildUrl));
  }

}
