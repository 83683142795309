import * as tslib_1 from "tslib";
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from 'src/app/config';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
export class ApiGoalService extends AbstractApiService {
    constructor(http) {
        super(http);
        this.http = http;
    }
    static createAttachmentUrl(userId) {
        return `${environment.eportfolio.api}${Config.Api.User}/${userId}/img`;
    }
    getGoalMine() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.get(this.buildApiURL([Config.Api.Goal]), this.transformUser);
        });
    }
    getGoal(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.get(this.buildApiURL([Config.Api.Goal, id]), this.transformUser);
        });
    }
    updateGoal(user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (user instanceof FormData) {
                return this.post(this.buildApiURL([Config.Api.Goal]), user, this.transformUser);
            }
            else {
                return this.post(this.buildApiURL([Config.Api.Goal]), user, this.transformUser);
            }
        });
    }
    deleteAttachment(userId, attachmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.deleteb(this.buildApiURL([Config.Api.Goal, userId, 'attach', attachmentId]));
        });
    }
    transformUser(user) {
        for (const attachment of user.attachments) {
            attachment.url = ApiGoalService.createAttachmentUrl(user.id);
        }
        return user;
    }
    handleError(operation = 'operation', result = null) {
        return (error) => {
            console.error(error);
            console.log(`${operation} failed: ${error && error.message ? error.message : 'no message.'}`);
            if (error.status === 400 && typeof error.error === 'object') {
                throw error.error;
            }
            return of(result);
        };
    }
}
