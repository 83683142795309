import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { AuthService } from './service/auth.service';
import { UserService } from './service/user.service';
import { ApiUserService } from './service/api/api-user.service';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    MaterialModule
  ],
  providers: [
    AuthService,
    UserService,
    ApiUserService,
  ]
})
export class CoreModule { }
