import * as tslib_1 from "tslib";
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
export class AbstractApiService {
    constructor(http) {
        this.http = http;
    }
    get(url, transform = this.tansform) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(url).pipe(map(transform), catchError(this.handleError(`GET ${url}`))).toPromise();
        });
    }
    getWithPaging(url, page, params = {}, transform = this.tansform) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            params.page = (typeof page === 'number' && page > 0) ? page : 1;
            return this.http.get(url, { params }).pipe(map(transform), catchError(this.handleError(`GET ${url}`))).toPromise();
        });
    }
    gets(url, params = null, transform = this.tansform) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(url, { params }).pipe(map(transform), catchError(this.handleError(`GET ${url}`, []))).toPromise();
        });
    }
    post(url, data, transform = this.tansform) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).pipe(map(transform), catchError(this.handleError(`POST ${url}`))).toPromise();
        });
    }
    postb(url, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).pipe(map(() => true), catchError(this.handleError(`POST ${url}`, false))).toPromise();
        });
    }
    put(url, data, transform = this.tansform) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.put(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).pipe(map(transform), catchError(this.handleError(`PUT ${url}`))).toPromise();
        });
    }
    putb(url, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.put(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).pipe(map(() => true), catchError(this.handleError(`PUT ${url}`, false))).toPromise();
        });
    }
    putbForm(url, data, transform = this.tansform) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.put(url, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                },
            }).pipe(map(() => true), catchError(this.handleError(`PUT ${url}`, false))).toPromise();
        });
    }
    delete(url, transform = this.tansform) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.delete(url).pipe(map(transform), catchError(this.handleError(`DELETE ${url}`))).toPromise();
        });
    }
    deleteb(url) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.delete(url).pipe(map(() => true), catchError(this.handleError(`DELETE ${url}`, false))).toPromise();
        });
    }
    buildApiURL(paths, params) {
        let tmp = Array.isArray(paths) ? paths.join('/') : paths;
        if (params) {
            for (const k of Object.keys(params)) {
                tmp = tmp.replace(`:${k}`, params[k].toString());
            }
        }
        return environment.eportfolio.api + (tmp.indexOf('/') === 0 ? tmp : `/${tmp}`);
    }
    handleError(operation = 'operation', result = null) {
        return (error) => {
            console.error(error);
            console.log(`${operation} failed: ${error && error.message ? error.message : 'no message.'}`);
            return of(result);
        };
    }
    tansform(value) {
        return value;
    }
}
