import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, OperatorFunction } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from 'src/app/config';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { User } from 'src/app/core/model/user.model';
import { GoalResponse } from 'src/app/shared/service/api/api-goal.service';
import { ValidationError } from 'src/app/shared/model/validation-error.model';

@Injectable()
export class ApiGoalService extends AbstractApiService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  private static createAttachmentUrl(userId: number): string {
    return `${environment.eportfolio.api}${Config.Api.User}/${userId}/img`;
  }

  public async getGoalMine(): Promise<User> {
    return  this.get(this.buildApiURL([Config.Api.Goal]), this.transformUser);
  }

  public async getGoal(id: number): Promise<User> {
    return  this.get(this.buildApiURL([Config.Api.Goal, id]), this.transformUser);
  }

  public async updateGoal(user: User | FormData): Promise<User> {
    if (user instanceof FormData) {
      return this.post<any>(this.buildApiURL([Config.Api.Goal]), user, this.transformUser);
    } else {
      return this.post(this.buildApiURL([Config.Api.Goal]), user, this.transformUser);
    }
  }

  public async deleteAttachment(userId: number, attachmentId: number): Promise<boolean> {
    return this.deleteb(this.buildApiURL([Config.Api.Goal, userId, 'attach', attachmentId]));
  }

  private transformUser(user: User): User {
    for (const attachment of user.attachments) {
      attachment.url = ApiGoalService.createAttachmentUrl(user.id);
    }
    return user;
  }

  protected handleError<T>(operation = 'operation', result: T = null): OperatorFunction<T, T> {
    return (error: any) => {
      console.error(error);
      console.log(`${operation} failed: ${error && error.message ? error.message : 'no message.'}`);

      if (error.status === 400 && typeof error.error === 'object') {
        throw error.error as ValidationError;
      }

      return of(result as T);
    };
  }
}

export interface GoalResponse {
  user: User;
}
