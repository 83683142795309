import * as tslib_1 from "tslib";
import { of } from 'rxjs';
import { Config } from 'src/app/config';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
export class ApiAspirationService extends AbstractApiService {
    constructor(http) {
        super(http);
        this.http = http;
    }
    getAspirations() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.get(this.buildApiURL([Config.Api.Aspiration]), this.transformAspirations);
        });
    }
    getAspirationResults(userId = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (userId !== null) {
                return this.get(this.buildApiURL([Config.Api.Aspiration, 'result', 'user', userId]), this.transformAspirationResults);
            }
            else {
                return this.get(this.buildApiURL([Config.Api.Aspiration, 'result']), this.transformAspirationResults);
            }
        });
    }
    getAspirationResult(aspirationId = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.get(this.buildApiURL([Config.Api.Aspiration, aspirationId, 'result']), this.transformAspirationResult);
        });
    }
    updateResult(aspirationId, aspirationJson) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.post(this.buildApiURL([Config.Api.Aspiration, aspirationId, 'result']), aspirationJson, this.transformAspiration);
        });
    }
    getCandidates() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.get(this.buildApiURL([Config.Api.Aspiration, 'names']), this.transformAspirationCandidates);
        });
    }
    transformAspiration(aspiration) {
        return aspiration;
    }
    transformAspirations(aspiration) {
        return aspiration;
    }
    transformAspirationResults(aspirationResultResponse) {
        return aspirationResultResponse;
    }
    transformAspirationResult(aspirationResult) {
        return aspirationResult;
    }
    transformAspirationCandidates(aspirationCandidate) {
        return aspirationCandidate;
    }
    handleError(operation = 'operation', result = null) {
        return (error) => {
            console.error(error);
            console.log(`${operation} failed: ${error && error.message ? error.message : 'no message.'}`);
            if (error.status === 400 && typeof error.error === 'object') {
                throw error.error;
            }
            return of(result);
        };
    }
}
