import { Routes } from '@angular/router';
import { RootComponent } from './root/root.component';
import { LoginComponent } from './login/login.component';
import { RelatedServiceAuthComponent } from './related-service-auth/related-service-auth.component';
import { AuthGuard } from './guard/auth.guard';
const ɵ0 = () => import("./activity/activity.module.ngfactory").then(m => m.ActivityModuleNgFactory), ɵ1 = () => import("./grade/grade.module.ngfactory").then(m => m.GradeModuleNgFactory), ɵ2 = () => import("./aspiration/aspiration.module.ngfactory").then(m => m.AspirationModuleNgFactory), ɵ3 = () => import("./goal/goal.module.ngfactory").then(m => m.GoalModuleNgFactory), ɵ4 = () => import("./confirm-sheet/confirm-sheet.module.ngfactory").then(m => m.ConfirmSHeetModuleNgFactory), ɵ5 = () => import("./setting/setting.module.ngfactory").then(m => m.SettingModuleNgFactory), ɵ6 = () => import("./student/student.module.ngfactory").then(m => m.StudentModuleNgFactory), ɵ7 = () => import("./user/user.module.ngfactory").then(m => m.UserModuleNgFactory), ɵ8 = () => import("./school/school.module.ngfactory").then(m => m.SchoolModuleNgFactory);
const routes = [
    { path: '', component: RootComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'related_service_auth', component: RelatedServiceAuthComponent },
    { path: 'activity', loadChildren: ɵ0 },
    { path: 'grade', loadChildren: ɵ1 },
    { path: 'aspiration', loadChildren: ɵ2 },
    { path: 'goal', loadChildren: ɵ3 },
    { path: 'confirm_sheet', loadChildren: ɵ4 },
    { path: 'setting', loadChildren: ɵ5 },
    { path: 'student', loadChildren: ɵ6 },
    { path: 'user', loadChildren: ɵ7 },
    { path: 'school', loadChildren: ɵ8 },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
