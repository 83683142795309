import { Injectable } from '@angular/core';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/config';
import { User } from 'src/app/core/model/user.model';
import { Followable } from 'src/app/shared/model/followable.model';

@Injectable()
export class ApiUserService extends AbstractApiService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public getMe(): Promise<User> {
    return this.get(this.buildApiURL(Config.Api.UserMe));
  }

  public getUser(id: number): Promise<User> {
    return this.get(this.buildApiURL([Config.Api.User, id]));
  }

  public async getFollows(): Promise<User[]> {
    return this.gets<User>(this.buildApiURL([Config.Api.Follow]));
  }

  public async getFollowers(): Promise<User[]> {
    return this.gets<User>(this.buildApiURL([Config.Api.Follower]));
  }

  public async addFollower(user: User): Promise<boolean> {
    return this.putb<User>(this.buildApiURL([Config.Api.User, user.id, 'follow']), user);
  }

  public async removeFollower(user: User): Promise<boolean> {
    return this.deleteb(this.buildApiURL([Config.Api.User, user.id, 'follow']));
  }

  public async getFollowable(id: number): Promise<Followable> {
    return this.get<Followable>(this.buildApiURL([Config.Api.User, id, 'followable']));
  }
}
