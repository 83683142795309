import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, OperatorFunction } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from 'src/app/config';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { Activity, SystemCategory, SearchParams, Tag } from 'src/app/shared/model/activity.model';
import { Pagingable } from 'src/app/shared/model/pagingable.model';
import { ValidationError } from 'src/app/shared/model/validation-error.model';
import { User } from 'src/app/core/model/user.model';

@Injectable()
export class ApiActivityService extends AbstractApiService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  private static createAttachmentUrl(activityId: number, attachmentId: number): string {
    return `${environment.eportfolio.api}${Config.Api.Activity}/${activityId}/attach/${attachmentId}`;
  }

  private static keyNameConvert(activity: Activity): Activity {
    activity.date_string = (activity as IncludeCovertTargetActivity).dateString;

    return activity;
  }

  public async getActivities(page: number = 1, params = {} as SearchParams): Promise<ActivityPaginator> {
    return this.getWithPaging<ActivityPaginator>(this.buildApiURL(Config.Api.Activity), page, params, this.transformActivities);
  }

  public async getActivity(id: number): Promise<Activity> {
    return  this.get(this.buildApiURL([Config.Api.Activity, id]), this.transformActivity);
  }

  public async createActivity(activity: Activity | FormData): Promise<Activity> {
    return this.post<any>(this.buildApiURL(Config.Api.Activity), activity, this.transformActivity);
  }

  public async updateActivity(activity: Activity | FormData): Promise<Activity> {
    if (activity instanceof FormData) {
      return this.post<any>(this.buildApiURL([Config.Api.Activity, activity.get('id').toString()]), activity, this.transformActivity);
    } else {
      return this.post(this.buildApiURL([Config.Api.Activity, activity.id]), activity, this.transformActivity);
    }
  }

  public async deleteActivity(id: number): Promise<boolean> {
    return this.deleteb(this.buildApiURL([Config.Api.Activity, id]));
  }

  public async deleteAttachment(activityId: number, attachmentId: number): Promise<boolean> {
    return this.deleteb(this.buildApiURL([Config.Api.Activity, activityId, 'attach', attachmentId]));
  }

  public async getTags(): Promise<Tag[]> {
    return this.get<Tag[]>(this.buildApiURL([Config.Api.Tag]));
  }

  public async getSystemCategories(): Promise<SystemCategory[]> {
    return this.gets<SystemCategory>(this.buildApiURL([Config.Api.SystemCategory]));
  }

  public async likeActivity(activity: Activity): Promise<Activity> {
    return this.post(this.buildApiURL([Config.Api.Activity, 'like', activity.id]), activity, this.transformForLike);
  }

  private transformActivity(activity: Activity): Activity {
    ApiActivityService.keyNameConvert(activity);
    for (const attachment of activity.attachments) {
      attachment.url = ApiActivityService.createAttachmentUrl(activity.id, attachment.id);
    }
    return activity;
  }

  private transformActivities(activityPaginator: ActivityPaginator): ActivityPaginator {
    for (const activity of activityPaginator.activities) {
      ApiActivityService.keyNameConvert(activity);
      for (const attachment of activity.attachments) {
        attachment.url = ApiActivityService.createAttachmentUrl(activity.id, attachment.id);
      }
    }
    return activityPaginator;
  }

  private transformForLike(activity: Activity): Activity {
    if (Array.isArray(activity.like_users)) {
      return activity;
    }
    const likeUsers: User[] = [];
    for (const key of Object.keys(activity.like_users)) {
      likeUsers.push(activity.like_users[key]);
    }
    activity.like_users = likeUsers;
    return activity;
  }

  protected handleError<T>(operation = 'operation', result: T = null): OperatorFunction<T, T> {
    return (error: any) => {
      console.error(error);
      console.log(`${operation} failed: ${error && error.message ? error.message : 'no message.'}`);

      if (error.status === 400 && typeof error.error === 'object') {
        throw error.error as ValidationError;
      }

      return of(result as T);
    };
  }
}

export interface ActivityPaginator extends Pagingable {
  activities: Activity[];
}

interface IncludeCovertTargetActivity extends Activity {
  dateString: string;
}
