import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Tag } from 'src/app/core/model/tag.model';
import { SystemCategory } from 'src/app/core/model/system-category.model';
import { ActivityRange } from 'src/app/core/model/activity-range.model';
import { isRoleStudent, User } from 'src/app/core/model/user.model';
import { StorageService } from 'src/app/shared/service/storage.service';
import { SearchService, AttributeStruct } from 'src/app/shared/service/search.service';
import { SearchParams } from '../../model/activity.model';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent implements OnInit {

  tags: Tag[] = Array();
  attributes: AttributeStruct = {grades: [], classes: []};
  systemCategories: SystemCategory[] = Array();
  activityRanges: ActivityRange[] = Array();
  user: User = null;

  onlyGradeClass: boolean;

  conditions: SearchParams = {
    range: 1,
    cid: null,
    tid: null,
    user_id: null,
    grade_id: null,
    class_id: null,
    keyword: null
  };

  constructor(
    public dialogRef: MatDialogRef<SearchDialogComponent>,
    private searchService: SearchService,
    private storageService: StorageService,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.router.url === '/student') {
      this.onlyGradeClass = true;
    }

    if (data !== null) {
      this.conditions = data;
    } else {
      this.initCondition();
    }
  }

  async ngOnInit() {

    if (!this.onlyGradeClass) {
      this.searchService.getSystemCategories().then((response) => {
        this.systemCategories = response;
      });

      this.searchService.getTags().then((response) => {
        this.tags = response;
      });
    }

    if (!this.isStudent()) {
      this.searchService.getAttributes().then((response) => {
        this.attributes = response;
      });
    } else {
      this.searchService.getActivityRanges().then((response) => {
        this.activityRanges = response;
      });
    }
  }

  itemClicked(itemType, item) {
    if (itemType === 'category') {
      if (this.conditions.cid === item.id) {
        this.initCategoryTag();
      } else {
        this.initCategoryTag();
        this.conditions.cid = item.id;
        this.conditions.keyword = '';
      }
    } else if (itemType === 'range') {
      this.conditions.range = item.id;
    } else if (itemType === 'tag') {
      if (this.conditions.tid === item.id) {
        this.initCategoryTag();
      } else {
        this.initCategoryTag();
        this.conditions.tid = item.id;
        this.conditions.keyword = '';
      }
    } else if (itemType === 'grade') {
      if (this.conditions.grade_id !== item.id) {
        this.conditions.grade_id = item.id;
      } else {
        this.conditions.grade_id = null;
      }
    } else if (itemType === 'class') {
      if (this.conditions.class_id !== item.id) {
        this.conditions.class_id = item.id;
      } else {
        this.conditions.class_id = null;
      }
    }
  }

  keywordChange() {
    this.initCategoryTag();
  }

  includeItem(itemType: string, item: any) {
    if (itemType === 'category') {
      if (this.conditions.cid === item.id) {
        return true;
      }
    } else if (itemType === 'range') {
      if (this.conditions.range === item.id) {
        return true;
      }
    } else if (itemType === 'tag') {
      if (this.conditions.tid === item.id) {
        return true;
      }
    } else if (itemType === 'grade') {
      if (this.conditions.grade_id === item.id) {
        return true;
      }
    } else if (itemType === 'class') {
      if (this.conditions.class_id === item.id) {
        return true;
      }
    }

    return false;
  }

  isStudent(): boolean {
    const user = this.storageService.getLoginUser().value;
    return isRoleStudent(user.roles);
  }

  isShowActivityRange(): boolean {
    if (!this.isStudent()) {
      return false;
    }

    const user = this.storageService.getLoginUser().value;

    if (!user || !user.schools || user.schools.length === 0) {
      return false;
    }

    const school = user.schools[0];
    return school.available_menu.some((menu) => {
      return menu === 'view_s_search_range';
    });
  }

  clearCondition() {
    this.initCondition();

    const conditions = this.exclusionNull(this.conditions);

    this.dialogRef.close(conditions);
  }

  exclusionNull<T>(data: T): T {
    if (typeof data !== 'object') {
      return data;
    }
    for (const kstr of Object.keys(data)) {
      const key: string = kstr;
      data[key] === null ? delete data[key] : this.exclusionNull(data[key]);
    }

    return data;
  }

  onSubmit() {
    const conditions = this.exclusionNull(this.conditions);

    this.dialogRef.close(conditions);
  }

  private initCondition() {
    this.initAttribute();
    this.initCategoryTag();
    this.conditions.keyword = '';
  }

  private initAttribute() {
    this.conditions.grade_id = null;
    this.conditions.class_id = null;
  }

  private initCategoryTag() {
    this.conditions.cid = null;
    this.conditions.tid = null;
  }
}

