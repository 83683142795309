import { Injectable } from '@angular/core';
import { SubjectValue } from 'src/app/core/class/subject-value';
import { User } from 'src/app/core/model/user.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private loginUser = new SubjectValue<User>();
  private followUsers = new SubjectValue<User[]>();

  constructor() { }

  public getLoginUser(): SubjectValue<User> {
    return this.loginUser;
  }

  public getFollowUsers(): SubjectValue<User[]> {
    return this.followUsers;
  }

}
