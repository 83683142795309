import { Component, ViewChild, OnInit } from '@angular/core';
import { AuthService } from './core/service/auth.service';
import { LayoutService } from './core/service/layout.service';
import { MatDrawer} from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild(MatDrawer, {static: true}) public drawer: MatDrawer;

  constructor(
    private authService: AuthService,
    private layoutService: LayoutService,
  ) {
  }

  ngOnInit() {
    this.initializeApp();

    this.layoutService.sideMenu = this.drawer;

    this.layoutService.breakpointEvent().subscribe(result => {
      this.changeHamburger();
    });
  }

  initializeApp() {
    if (this.authService.isAuthenticated) {
      this.layoutService.showNavigation();
      this.layoutService.showHeader();
      this.layoutService.showFixedBottomButton();
    }
  }

  isOpenedSideMenu(): boolean {
    return this.layoutService.sideMenu.opened;
  }

  private changeHamburger(): void {
    if (this.layoutService.isSmall()) {
      this.layoutService.showHamburger();
    } else {
      this.layoutService.hideHamburger();
    }
  }
}
