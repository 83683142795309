/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fixed-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./fixed-button.component";
import * as i6 from "../../../core/service/layout.service";
import * as i7 from "../../service/storage.service";
import * as i8 from "@angular/router";
var styles_FixedButtonComponent = [i0.styles];
var RenderType_FixedButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FixedButtonComponent, data: {} });
export { RenderType_FixedButtonComponent as RenderType_FixedButtonComponent };
function View_FixedButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "label__icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["add_circle"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u5B66\u3073\u306E\u8A18\u9332\u5165\u529B"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_FixedButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FixedButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible && _co.visibleLabel(_co.Role.Student)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FixedButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fixed-button", [], null, null, null, View_FixedButtonComponent_0, RenderType_FixedButtonComponent)), i1.ɵdid(1, 114688, null, 0, i5.FixedButtonComponent, [i6.LayoutService, i7.StorageService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FixedButtonComponentNgFactory = i1.ɵccf("app-fixed-button", i5.FixedButtonComponent, View_FixedButtonComponent_Host_0, {}, {}, []);
export { FixedButtonComponentNgFactory as FixedButtonComponentNgFactory };
