import * as tslib_1 from "tslib";
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { Config } from 'src/app/config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ApiConfirmSheetService extends AbstractApiService {
    constructor(http) {
        super(http);
        this.http = http;
    }
    getResumes(userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.get(this.buildApiURL([Config.Api.ConfirmSheet, 'resume', userId]));
        });
    }
    getAchievement(userId, resumeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const arrayForBuildUrl = resumeId ?
                [Config.Api.ConfirmSheet, 'achievement', userId, 'resume', resumeId]
                : [Config.Api.ConfirmSheet, 'achievement', userId];
            return this.get(this.buildApiURL(arrayForBuildUrl));
        });
    }
    getSheet(userId = null, resumeId = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const arrayForBuildUrl = resumeId ?
                [Config.Api.ConfirmSheet, userId, 'resume', resumeId]
                : [Config.Api.ConfirmSheet, userId];
            return this.get(this.buildApiURL(arrayForBuildUrl));
        });
    }
}
ApiConfirmSheetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiConfirmSheetService_Factory() { return new ApiConfirmSheetService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiConfirmSheetService, providedIn: "root" });
