import { Injectable } from '@angular/core';
import { MatDrawer} from '@angular/material/sidenav';
import { NgxMasonryComponent } from 'ngx-masonry/ngx-masonry';
import { Observable } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Navigation } from 'src/app/app-const';
import { NavigationService } from 'src/app/core/service/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  BreakPoint: string;

  public visibleHeader: boolean;
  public visibleNavigation: boolean;
  public visibleFixedBottomButton: boolean;
  public navigationOpened: boolean;
  public visibleHamburger: boolean;

  public sideMenu: MatDrawer;
  public masonry: NgxMasonryComponent;

  constructor(
    private navigationService: NavigationService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.BreakPoint = '(max-width: 768px)';
    this.visibleHamburger = true;
  }

  public showHeader(): void {
    this.visibleHeader = true;
  }

  public hideHeader(): void {
    this.visibleHeader = false;
  }

  public showNavigation(): void {
    this.visibleNavigation = true;
  }

  public hideNavigation(): void {
    this.visibleNavigation = false;
  }

  public showFixedBottomButton(): void {
    this.visibleFixedBottomButton = true;
  }

  public hideFixedBottomButton(): void {
    this.visibleFixedBottomButton = false;
  }

  public toggleNavigation(): void {
    this.navigationOpened = !this.navigationOpened;
  }

  public toggleHamburger(): void {
    this.sideMenu.toggle();

    this.updateMasonry();
  }

  public showHamburger(): void {
    this.visibleHamburger = true;
    this.sideMenu.close();

    this.updateMasonry();
  }

  public hideHamburger(): void {
    // this.visibleHamburger = false;
    this.sideMenu.open();

    this.updateMasonry();
  }

  public closeNavigation(): void {
    this.navigationOpened = false;
  }

  public breakpointEvent(): Observable<BreakpointState> {
    return this.breakpointObserver.observe([
      this.BreakPoint
    ]);
  }

  public isSmall(): boolean {
    return this.breakpointObserver.isMatched(this.BreakPoint);
  }

  private updateMasonry(): void {
    if (this.masonry) {
      setTimeout(() => {
        this.masonry.layout();
      }, 500);
    }
  }

  initMenuList() {
    this.navigationService.initMenuList();
  }
  createMenuList(role: string) {
    this.navigationService.createMenuList(role);
  }
  setMenuList(menuList: any[]) {
    this.navigationService.setMenuList(menuList);
  }
  getMenuList() {
    return this.navigationService.getMenuList();
  }
  selectCurrentMenu(selectMenu: Navigation): void {
    this.navigationService.selectCurrentMenu(selectMenu);
  }
  setDefaultSelectMenu(role) {
    this.navigationService.setDefaultSelectMenu(role);
  }
}
