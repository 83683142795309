import { Injectable } from '@angular/core';
import { ApiGoalService } from 'src/app/shared/service/api/api-goal.service';
import { User } from 'src/app/core/model/user.model';

@Injectable()
export class GoalService {

  constructor(private apiGoalService: ApiGoalService) { }

  public create(): User {
    return {
      id: 0,
      last_name: '',
      first_name: '',
      last_name_kana: '',
      first_name_kana: '',
      gender: '',
      description: '',
      goal: '',
      birthday: '',
      name_jis: '',
      student_number: 0,
      created_at: '',
      updated_at: '',
      attachments: [],
      attributes: [],
      username: '',
      email: '',
      roles: [],
    } as User;
  }

  public getGoal(id: number): Promise<User> {
    return this.apiGoalService.getGoal(id);
  }

  public getGoalMine(): Promise<User> {
    return this.apiGoalService.getGoalMine();
  }

  public async updateGoal(user: User | FormData): Promise<User> {
    return this.apiGoalService.updateGoal(user);
  }

  public async deleteAttachment(userId: number, attachmentId: number): Promise<boolean> {
    return this.apiGoalService.deleteAttachment(userId, attachmentId);
  }
}
