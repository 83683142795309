import { Utility } from 'src/app/shared/class/utility';
import { Config } from 'src/app/config';
import { Role } from 'src/app/core/model/user.model';
import { Navigation, StudentMenu, TeacherMenu, AdminMenu, RecruitMenu } from 'src/app/app-const';
import { AuthenticationError } from 'src/app/shared/class/authentication-error';
import * as i0 from "@angular/core";
export class NavigationService {
    constructor() {
    }
    initMenuList() {
        this.menuList = [];
    }
    setMenuList(menuList) {
        this.menuList = menuList;
    }
    getMenuList() {
        return this.menuList;
    }
    createMenuList(role) {
        const service = Utility.getStorageItem(Config.StorageKey.RelatedService);
        let menuList = [];
        switch (role) {
            case Role.Student:
                menuList = StudentMenu;
                break;
            case Role.Admin:
                menuList = AdminMenu;
                break;
            case Role.Teacher:
                menuList = TeacherMenu;
                break;
            case Role.Recruit:
                menuList = RecruitMenu;
                break;
            default:
                throw new AuthenticationError();
        }
        // 「ログイン画面に戻る」「メインメニューへ」の選択
        if (!service || !service.service) {
            menuList = menuList.filter((element, index, array) => {
                return (element.item !== Navigation.ReturnService);
            });
        }
        else {
            menuList = menuList.filter((element, index, array) => {
                return (element.item !== Navigation.Logout && element.item !== Navigation.ReturnService);
            });
        }
        this.setMenuList(menuList);
        const currentMenu = Utility.getStorageItem(Config.StorageKey.Menu);
        if (currentMenu) {
            this.selectCurrentMenu(currentMenu.item);
        }
        else {
            this.setDefaultSelectMenu(role);
        }
    }
    setDefaultSelectMenu(role) {
        console.log('setDefaultSelectMenu', role);
        switch (role) {
            case Role.Student:
                this.selectCurrentMenu(Navigation.Top);
                break;
            case Role.Admin:
                this.selectCurrentMenu(Navigation.School);
                break;
            case Role.Teacher:
                this.selectCurrentMenu(Navigation.AllActivities);
                break;
            case Role.Recruit:
                this.selectCurrentMenu(Navigation.AllActivities);
                break;
            default:
                throw new AuthenticationError();
        }
    }
    selectCurrentMenu(selectMenu) {
        this.menuList.forEach(menu => {
            if (menu.item === selectMenu) {
                menu.selected = true;
                Utility.setStorageItem(Config.StorageKey.Menu, menu);
                return;
            }
            menu.selected = false;
            return;
        });
    }
}
NavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(); }, token: NavigationService, providedIn: "root" });
