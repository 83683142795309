/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./activity-graph.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./activity-graph.component";
import * as i3 from "../../service/confirm-sheet.service";
var styles_ActivityGraphComponent = [i0.styles];
var RenderType_ActivityGraphComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivityGraphComponent, data: {} });
export { RenderType_ActivityGraphComponent as RenderType_ActivityGraphComponent };
export function View_ActivityGraphComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { canvas: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickActivityGraph($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["canvas", 1]], null, 0, "canvas", [], null, null, null, null, null))], null, null); }
export function View_ActivityGraphComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activity-graph", [], null, null, null, View_ActivityGraphComponent_0, RenderType_ActivityGraphComponent)), i1.ɵdid(1, 4243456, null, 0, i2.ActivityGraphComponent, [i3.ConfirmSheetService], null, null)], null, null); }
var ActivityGraphComponentNgFactory = i1.ɵccf("app-activity-graph", i2.ActivityGraphComponent, View_ActivityGraphComponent_Host_0, { data: "data" }, { clickGraphEvent: "clickGraphEvent" }, []);
export { ActivityGraphComponentNgFactory as ActivityGraphComponentNgFactory };
