import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/config';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { Setting } from 'src/app/core/model/setting.model';

@Injectable()
export class ApiSettingService extends AbstractApiService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public async getSettingResponse(): Promise<SettingResponse> {
    return this.get(this.buildApiURL([Config.Api.Setting]), this.transformSettingResponse);
  }

  public async updateNoticeSetting(setting: Setting): Promise<Setting> {
    return this.put(this.buildApiURL([Config.Api.SettingNotice]), setting, this.transformSetting);
  }

  public async updateSocialSetting(setting: Setting): Promise<Setting> {
    return this.put(this.buildApiURL([Config.Api.SettingSocial]), setting, this.transformSetting);
  }

  private transformSettingResponse(settingResponse: SettingResponse): SettingResponse {
    return settingResponse;
  }

  private transformSetting(setting: Setting): Setting {
    return setting;
  }
}

export interface SettingHash {
  key: string;
  name: string;
}

export interface SettingResponse {
  allow_scout: boolean;
  setting: Setting;
  like_scopes: SettingHash[];
  follow_scopes: SettingHash[];
  scout_scopes: SettingHash[];
  notice_actions: SettingHash[];
}
