/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./related-service-auth.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./related-service-auth.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/router";
import * as i6 from "../core/service/auth.service";
import * as i7 from "../core/service/layout.service";
import * as i8 from "../shared/service/setting.service";
import * as i9 from "../shared/service/storage.service";
var styles_RelatedServiceAuthComponent = [i0.styles];
var RenderType_RelatedServiceAuthComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RelatedServiceAuthComponent, data: {} });
export { RenderType_RelatedServiceAuthComponent as RenderType_RelatedServiceAuthComponent };
function View_RelatedServiceAuthComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "orbit-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "orbit-spinner__orbit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "orbit-spinner__orbit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "orbit-spinner__orbit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u8A8D\u8A3C\u51E6\u7406\u4E2D..."]))], null, null); }
export function View_RelatedServiceAuthComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedServiceAuthComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.logined; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RelatedServiceAuthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-related-service-auth", [], null, null, null, View_RelatedServiceAuthComponent_0, RenderType_RelatedServiceAuthComponent)), i1.ɵdid(1, 114688, null, 0, i3.RelatedServiceAuthComponent, [i4.Title, i5.Router, i5.ActivatedRoute, i6.AuthService, i7.LayoutService, i8.SettingService, i9.StorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RelatedServiceAuthComponentNgFactory = i1.ɵccf("app-related-service-auth", i3.RelatedServiceAuthComponent, View_RelatedServiceAuthComponent_Host_0, {}, {}, []);
export { RelatedServiceAuthComponentNgFactory as RelatedServiceAuthComponentNgFactory };
