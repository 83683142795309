import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../core/service/auth.service";
import * as i3 from "../core/service/user.service";
import * as i4 from "../shared/service/storage.service";
export class AuthGuard {
    constructor(router, authService, userService, storageService) {
        this.router = router;
        this.authService = authService;
        this.userService = userService;
        this.storageService = storageService;
    }
    canActivate(next, state) {
        return new Observable((subscriber) => {
            const isAuthenticated = this.authService.isAuthenticated;
            if (!isAuthenticated) {
                this.router.navigate(['/login']);
                subscriber.next(false);
                return;
            }
            const storageUser = this.storageService.getLoginUser().value;
            if (storageUser != null) {
                subscriber.next(isAuthenticated);
                return;
            }
            this.userService.getMe().then((user) => {
                this.storageService.getLoginUser().onChanged(user);
                subscriber.next(isAuthenticated);
            }).catch(() => {
                subscriber.next(false);
            });
        });
    }
    canLoad(route, segments) {
        return this.authService.isAuthenticated;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.UserService), i0.ɵɵinject(i4.StorageService)); }, token: AuthGuard, providedIn: "root" });
