import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/service/user.service';
import { isRoleAdmin, isRoleRecruit, isRoleTeacher, isRoleStudent } from 'src/app/core/model/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

  constructor(
    private router: Router,
    private userService: UserService) { }

  async ngOnInit() {
    const user = await this.userService.getMe();
    const roles = (user && Array.isArray(user.roles)) ? user.roles : [];
    if (isRoleAdmin(roles)) {
      this.router.navigate(['/school']);
      return;
    }
    if (isRoleRecruit(roles)) {
      this.router.navigate(['/student']);
      return;
    }
    if (isRoleTeacher(roles)) {
      this.router.navigate(['/student/activity']);
      return;
    }
    if (isRoleStudent(roles)) {
      this.router.navigate(['/activity']);
      return;
    }
    this.router.navigate(['/error/unauthorized']);
  }

}
