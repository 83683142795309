import { Injectable } from '@angular/core';
import { ApiLikeService } from './api/api-like.service';
import { Like } from '../model/like.model';

@Injectable()
export class LikeService {

  constructor(private apiLikeService: ApiLikeService) { }

  public async getLikeCount(): Promise<Like> {
    return this.apiLikeService.getLikeCount();
  }
}
