import { Injectable } from '@angular/core';
import { Readed } from '../model/notice.model';
import { ApiNoticeService } from './api/api-notice.service';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {

  constructor(
    private apiNoticeService: ApiNoticeService
  ) { }

  public isAllRead(count: number): boolean {
    if (count === 0) {
      return true;
    }
    return false;
  }

  public getNotices(page: number = 1): Promise<any> {
    return this.apiNoticeService.getNotices(page);
  }

  public getUnreadCount(): Promise<any> {
    return this.apiNoticeService.getUnreadCount();
  }

  public updateToRead(readed: Readed): Promise<any> {
    return this.apiNoticeService.updateToRead(readed);
  }
}
