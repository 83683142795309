import { Injectable } from '@angular/core';
import { SettingScoutScope } from 'src/app/app-const';
import { Utility } from 'src/app/shared/class/utility';
import { Config } from 'src/app/config';
import { ApiSettingService, SettingResponse } from 'src/app/shared/service/api/api-setting.service';
import { Setting } from 'src/app/core/model/setting.model';

@Injectable()
export class SettingService {

  constructor(private apiSettingService: ApiSettingService) { }

  public getScoutScope(): SettingScoutScope {
    const storage = Utility.getStorageItem(Config.StorageKey.ScoutScope);
    if (storage === null) {
      const initialScoutScopeType = { type: SettingScoutScope.Disable };
      Utility.setStorageItem(Config.StorageKey.ScoutScope, initialScoutScopeType);
      return initialScoutScopeType.type;
    }
    return storage.type;
  }

  public updateScoutScope(type: SettingScoutScope): void {
    const typeObj: { [key: string]: SettingScoutScope; } = { type };
    Utility.setStorageItem(Config.StorageKey.ScoutScope, typeObj);
  }

  public updateScoutScopeString(type: string): void {
    let typeObj: SettingScoutScope = SettingScoutScope.Disable;
    if (type === SettingScoutScope.Each) {
      typeObj = SettingScoutScope.Each;
    } else if (type === SettingScoutScope.Unlimited) {
      typeObj = SettingScoutScope.Unlimited;
    }
    this.updateScoutScope(typeObj);
  }

  public async getSetting(): Promise<SettingResponse> {
    return this.apiSettingService.getSettingResponse();
  }

  public async updateSocialSetting(setting: Setting): Promise<Setting> {
    return this.apiSettingService.updateSocialSetting(setting);
  }

  public async updateNoticeSetting(setting: Setting): Promise<Setting> {
    return this.apiSettingService.updateNoticeSetting(setting);
  }
}
