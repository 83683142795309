export class AuthenticationError implements Error {

  public name = 'AuthenticationError';

  constructor(public message: string = '') {
  }

  toString() {
    return this.name + ': ' + this.message;
  }
}
