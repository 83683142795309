import * as tslib_1 from "tslib";
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { Config } from 'src/app/config';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ApiTagService extends AbstractApiService {
    constructor(http) {
        super(http);
        this.http = http;
    }
    getTags() {
        return this.get(this.buildApiURL(Config.Api.Tag), (tags) => {
            return tags.map(tag => {
                tag.editable = ('' + tag.editable) === '1';
                return tag;
            });
        });
    }
    postTag(tag) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.post(this.buildApiURL([Config.Api.Tag]), tag);
        });
    }
    putTag(tag) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.put(this.buildApiURL([Config.Api.Tag, tag.id]), tag, (t => {
                return t.tags;
            }));
        });
    }
    deleteTag(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.deleteb(this.buildApiURL([Config.Api.Tag, id]));
        });
    }
    getActivityTags(page = 1, params = {}) {
        return this.getWithPaging(this.buildApiURL(Config.Api.ActivityTag), page, params);
    }
    handleError(operation = 'operation', result = null) {
        return (error) => {
            console.error(error);
            console.log(`${operation} failed: ${error && error.message ? error.message : 'no message.'}`);
            if (error.status === 400 && typeof error.error === 'object') {
                throw error.error;
            }
            return of(result);
        };
    }
}
ApiTagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiTagService_Factory() { return new ApiTagService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiTagService, providedIn: "root" });
