import { Attachment } from 'src/app/core/model/attachment.model';
import { Setting } from './setting.model';
import { School } from './school.model';

export interface User {
  id: number;
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  gender: string;
  description: string;
  goal: string;
  birthday: string;
  name_jis: string;
  student_number: number;
  created_at: string;
  updated_at: string;
  attachments: Attachment[];
  attributes: Attribute[];
  username: string;
  email: string;
  roles: Array<string>;
  schools: Array<School>;
  setting: Setting;
}

interface Attribute {
  name: string;
  division: string;
}

export enum Role {
  Student = 'ROLE_STUDENT',
  Teacher = 'ROLE_TEACHER',
  Recruit = 'ROLE_RECRUIT',
  Admin = 'ROLE_ADMIN',
}

export function isRoleStudent(roles: string[]): boolean {
  return roles.includes(Role.Student.toString());
}

export function isRoleTeacher(roles: string[]): boolean {
  return roles.includes(Role.Teacher.toString());
}

export function isRoleRecruit(roles: string[]): boolean {
  return roles.includes(Role.Recruit.toString());
}

export function isRoleAdmin(roles: string[]): boolean {
  return roles.includes(Role.Admin.toString());
}
