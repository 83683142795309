import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RootComponent } from './root/root.component';
import { LoginComponent } from './login/login.component';
import { RelatedServiceAuthComponent } from './related-service-auth/related-service-auth.component';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  { path: '', component: RootComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'related_service_auth', component: RelatedServiceAuthComponent },
  { path: 'activity', loadChildren: () => import('./activity/activity.module').then( m => m.ActivityModule)},
  { path: 'grade', loadChildren: () => import('./grade/grade.module').then( m => m.GradeModule)},
  { path: 'aspiration', loadChildren: () => import('./aspiration/aspiration.module').then( m => m.AspirationModule)},
  { path: 'goal', loadChildren: () => import('./goal/goal.module').then( m => m.GoalModule)},
  { path: 'confirm_sheet', loadChildren: () => import('./confirm-sheet/confirm-sheet.module').then( m => m.ConfirmSHeetModule)},
  { path: 'setting', loadChildren: () => import('./setting/setting.module').then( m => m.SettingModule)},
  { path: 'student', loadChildren: () => import('./student/student.module').then( m => m.StudentModule)},
  { path: 'user', loadChildren: () => import('./user/user.module').then( m => m.UserModule)},
  { path: 'school', loadChildren: () => import('./school/school.module').then( m => m.SchoolModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
