import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { UserService } from '../core/service/user.service';
import { StorageService } from '../shared/service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private storageService: StorageService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      const isAuthenticated = this.authService.isAuthenticated;

      if (!isAuthenticated) {
        this.router.navigate(['/login']);
        subscriber.next(false);
        return;
      }

      const storageUser = this.storageService.getLoginUser().value;
      if (storageUser != null) {
        subscriber.next(isAuthenticated);
        return;
      }

      this.userService.getMe().then((user) => {
        this.storageService.getLoginUser().onChanged(user);
        subscriber.next(isAuthenticated);
      }).catch(() => {
        subscriber.next(false);
      });
    });
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isAuthenticated;
  }
}
