import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMasonryModule } from 'ngx-masonry';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NavigationComponent } from './components/navigation/navigation.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderForSearchComponent } from './components/header-for-search/header-for-search.component';
import { SearchDialogComponent } from './components/search-dialog/search-dialog.component';
import { TagDialogComponent } from './components/tag-dialog/tag-dialog.component';
import { MaterialModule } from '../core/material.module';
import { OneColumnComponent } from './components/one-column/one-column.component';
import { MultiColumnComponent } from './components/multi-column/multi-column.component';
import { NoticeDialogComponent } from './components/notice-dialog/notice-dialog.component';
import { FollowDialogComponent } from './components/follow-dialog/follow-dialog.component';
import { UtilityService } from './service/utility.service';
import { ActivityService } from './service/activity.service';
import { ApiActivityService } from './service/api/api-activity.service';
import { SettingService } from './service/setting.service';
import { ApiSettingService } from './service/api/api-setting.service';
import { GoalService } from './service/goal.service';
import { ApiGoalService } from './service/api/api-goal.service';
import { AspirationService } from './service/aspiration.service';
import { ApiAspirationService } from './service/api/api-aspiration.service';
import { LikeService } from './service/like.service';
import { ApiLikeService } from './service/api/api-like.service';
import { FormatUserNamePipe } from './pipe/format-user-name.pipe';
import { LengthPipe } from './pipe/length.pipe';
import { IndexOfPipe } from './pipe/index-of.pipe';
import { TrustUrlPipe } from './pipe/trust-url.pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { GoalComponent } from './components/goal/goal.component';
import { GradeListComponent } from './components/grade-list/grade-list.component';
import { GradeComponent } from './components/grade/grade.component';
import { AspirationListComponent } from './components/aspiration-list/aspiration-list.component';
import { AspirationComponent } from './components/aspiration/aspiration.component';
import { ConfirmSheetComponent } from './components/confirm-sheet/confirm-sheet.component';
import { ActivityPanelComponent } from './components/activity-panel/activity-panel.component';
import { ProfileImagePipe } from './pipe/profile-image.pipe';
import { FromDatePipe } from './pipe/from-date.pipe';
import { ActivityGraphComponent } from './components/activity-graph/activity-graph.component';
import { GradeGraphComponent } from './components/grade-graph/grade-graph.component';
import { HamburgerComponent } from './components/hamburger/hamburger.component';
import { ActivityDateFormatPipe } from './pipe/activity-date-format.pipe';
import { DateRangeFormatPipe } from './pipe/date-range-format.pipe';
import { DateFormatPipe } from './pipe/date-format.pipe';
import { LikePipe } from './pipe/like.pipe';
import { UserBelongingComponent } from './components/user-belonging/user-belonging.component';
import { FixedButtonComponent } from './components/fixed-button/fixed-button.component';
import { EnumToArrayPipe } from './pipe/enum-to-array.pipe';
import { KeyValueEntriesPipe } from './pipe/key-value-entries.pipe';

@NgModule({
  declarations: [
    NavigationComponent,
    HeaderComponent,
    HeaderForSearchComponent,
    SearchDialogComponent,
    TagDialogComponent,
    OneColumnComponent,
    MultiColumnComponent,
    NoticeDialogComponent,
    FollowDialogComponent,
    FormatUserNamePipe,
    LengthPipe,
    IndexOfPipe,
    TrustUrlPipe,
    ProfileImagePipe,
    DateRangeFormatPipe,
    DateFormatPipe,
    ActivityDateFormatPipe,
    LikePipe,
    GoalComponent,
    GradeListComponent,
    GradeComponent,
    AspirationListComponent,
    AspirationComponent,
    ConfirmSheetComponent,
    ActivityPanelComponent,
    FromDatePipe,
    ActivityGraphComponent,
    GradeGraphComponent,
    HamburgerComponent,
    UserBelongingComponent,
    FixedButtonComponent,
    EnumToArrayPipe,
    KeyValueEntriesPipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxMasonryModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    InfiniteScrollModule,
  ],
  exports: [
    NavigationComponent,
    HeaderComponent,
    HeaderForSearchComponent,
    OneColumnComponent,
    MultiColumnComponent,
    GoalComponent,
    GradeListComponent,
    GradeComponent,
    AspirationListComponent,
    AspirationComponent,
    ConfirmSheetComponent,
    FixedButtonComponent,
    FormatUserNamePipe,
    LengthPipe,
    IndexOfPipe,
    TrustUrlPipe,
    ProfileImagePipe,
    DateRangeFormatPipe,
    DateFormatPipe,
    ActivityDateFormatPipe,
    LikePipe,
    EnumToArrayPipe,
    KeyValueEntriesPipe,
  ],
  entryComponents: [
    SearchDialogComponent,
    TagDialogComponent,
    NoticeDialogComponent,
    ActivityGraphComponent,
    GradeGraphComponent
  ],
  providers: [
    UtilityService,
    ActivityService,
    ApiActivityService,
    SettingService,
    ApiSettingService,
    GoalService,
    ApiGoalService,
    AspirationService,
    ApiAspirationService,
    LikeService,
    ApiLikeService,
  ]
})
export class SharedModule { }
