import { SubjectValue } from 'src/app/core/class/subject-value';
import * as i0 from "@angular/core";
export class StorageService {
    constructor() {
        this.loginUser = new SubjectValue();
        this.followUsers = new SubjectValue();
    }
    getLoginUser() {
        return this.loginUser;
    }
    getFollowUsers() {
        return this.followUsers;
    }
}
StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
