import { Injectable } from '@angular/core';
import { User } from 'src/app/core/model/user.model';
import { Followable } from 'src/app/shared/model/followable.model';
import { ApiUserService } from 'src/app/core/service/api/api-user.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiUserService: ApiUserService) {
  }

  public getMe(): Promise<User> {
    return this.apiUserService.getMe();
  }

  public getUser(id: number): Promise<User> {
    return this.apiUserService.getUser(id);
  }

  public async getFollows(): Promise<User[]> {
    return this.apiUserService.getFollows();
  }

  public async getFollowers(): Promise<User[]> {
    return this.apiUserService.getFollowers();
  }

  public async getFollowable(id: number): Promise<Followable> {
    return this.apiUserService.getFollowable(id);
  }

  public async addFollower(user: User): Promise<boolean> {
    return this.apiUserService.addFollower(user);
  }

  public async removeFollower(user: User): Promise<boolean> {
    return this.apiUserService.removeFollower(user);
  }
}
