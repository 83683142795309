import { HttpClient } from '@angular/common/http';
import { OperatorFunction, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Pagingable } from 'src/app/shared/model/pagingable.model';

type Map<T> = (value: T) => T;

export abstract class AbstractApiService {

  constructor(protected http: HttpClient) { }

  protected async get<T, R = T>(url: string, transform: Map<R> = this.tansform): Promise<R> {
    return this.http.get<R>(url).pipe(
      map(transform),
      catchError(this.handleError<R>(`GET ${url}`)),
    ).toPromise();
  }

  protected async getWithPaging<T extends Pagingable, R = T>(url: string, page: number, params: any = {},
                                                             transform: Map<R> = this.tansform): Promise<R> {
    params.page = (typeof page === 'number' && page > 0) ? page : 1;
    return this.http.get<R>(url, {params}).pipe(
      map(transform),
      catchError(this.handleError<R>(`GET ${url}`)),
    ).toPromise();
  }

  protected async gets<T, R = T>(url: string, params: any = null, transform: Map<R[]> = this.tansform): Promise<R[]> {
    return this.http.get<R[]>(url, {params}).pipe(
      map(transform),
      catchError(this.handleError<R[]>(`GET ${url}`, [])),
    ).toPromise();
  }

  protected async post<T, R = T>(url: string, data: T, transform: Map<R> = this.tansform): Promise<R> {
    return this.http.post<R>(url, data, {
      headers: {
        'Content-Type':  'application/json',
      },
    }).pipe(
      map(transform),
      catchError(this.handleError<R>(`POST ${url}`)),
    ).toPromise();
  }

  protected async postb<T>(url: string, data: T): Promise<boolean> {
    return this.http.post(url, data, {
      headers: {
        'Content-Type':  'application/json',
      },
    }).pipe(
      map(() => true),
      catchError(this.handleError<boolean>(`POST ${url}`, false)),
    ).toPromise<boolean>();
  }

  protected async put<T, R = T>(url: string, data: T, transform: Map<R> = this.tansform): Promise<R> {
    return this.http.put<R>(url, data, {
      headers: {
        'Content-Type':  'application/json',
      },
    }).pipe(
      map(transform),
      catchError(this.handleError<R>(`PUT ${url}`)),
    ).toPromise();
  }

  protected async putb<T>(url: string, data: T): Promise<boolean> {
    return this.http.put(url, data, {
      headers: {
        'Content-Type':  'application/json',
      },
    }).pipe(
      map(() => true),
      catchError(this.handleError<boolean>(`PUT ${url}`, false)),
    ).toPromise<boolean>();
  }

  protected async putbForm<T>(url: string, data: FormData, transform: Map<T> = this.tansform): Promise<boolean> {
    return this.http.put(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    }).pipe(
      map(() => true),
      catchError(this.handleError<boolean>(`PUT ${url}`, false)),
    ).toPromise<boolean>();
  }

  protected async delete<T>(url: string, transform: Map<T> = this.tansform): Promise<T> {
    return this.http.delete<T>(url).pipe(
      map(transform),
      catchError(this.handleError<T>(`DELETE ${url}`)),
    ).toPromise();
  }

  protected async deleteb(url: string): Promise<boolean> {
    return this.http.delete(url).pipe(
      map(() => true),
      catchError(this.handleError<boolean>(`DELETE ${url}`, false)),
    ).toPromise<boolean>();
  }

  protected buildApiURL(paths: string | Array<string | number>, params?: {[key: string]: string | number}): string {
    let tmp = Array.isArray(paths) ? paths.join('/') : paths;

    if (params) {
      for (const k of Object.keys(params)) {
        tmp = tmp.replace(`:${k}`, params[k].toString());
      }
    }

    return environment.eportfolio.api + (tmp.indexOf('/') === 0 ? tmp : `/${tmp}`);
  }

  protected handleError<T>(operation = 'operation', result: T = null): OperatorFunction<T, T> {
    return (error: any) => {
      console.error(error);
      console.log(`${operation} failed: ${error && error.message ? error.message : 'no message.'}`);

      return of(result as T);
    };
  }

  private tansform<T>(value: T): T {
    return value;
  }

}
