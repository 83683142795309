import * as tslib_1 from "tslib";
export class LikeService {
    constructor(apiLikeService) {
        this.apiLikeService = apiLikeService;
    }
    getLikeCount() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiLikeService.getLikeCount();
        });
    }
}
