import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Tag, SearchParams } from 'src/app/core/model/tag.model';
import { AbstractApiService } from 'src/app/core/class/abstract-api.service';
import { Config } from 'src/app/config';
import { OperatorFunction, of } from 'rxjs';
import { ValidationError } from '../../model/validation-error.model';
import { Paginator } from '../../model/paginator.model';

@Injectable({
  providedIn: 'root'
})
export class ApiTagService extends AbstractApiService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public getTags(): Promise<Tag[]> {
    return this.get(this.buildApiURL(Config.Api.Tag), (tags) => {
      return tags.map(tag => {
        tag.editable = ('' + tag.editable) === '1';
        return tag;
      });
    });
  }

  public async postTag(tag: Tag): Promise<Tag> {
    return this.post<Tag>(this.buildApiURL([Config.Api.Tag]), tag);
  }

  public async putTag(tag: Tag): Promise<Tag> {
    return this.put<any>(this.buildApiURL([Config.Api.Tag, tag.id]), tag, (t => {
      return t.tags;
    }));
  }

  public async deleteTag(id: number): Promise<boolean> {
    return this.deleteb(this.buildApiURL([Config.Api.Tag, id]));
  }

  public getActivityTags(page: number = 1, params = {} as SearchParams): Promise<ActivityTagResponse> {
    return this.getWithPaging(this.buildApiURL(Config.Api.ActivityTag), page, params);
  }

  protected handleError<T>(operation = 'operation', result: T = null): OperatorFunction<T, T> {
    return (error: any) => {
      console.error(error);
      console.log(`${operation} failed: ${error && error.message ? error.message : 'no message.'}`);

      if (error.status === 400 && typeof error.error === 'object') {
        throw error.error as ValidationError;
      }

      return of(result as T);
    };
  }
}

export interface ActivityTagResponse {
  tags: Tag[];
  paginator: Paginator;
}
