<div class="container">
  <h3>タグ一覧</h3>
  <mat-divider></mat-divider>
  <div class="tag">
    <ul class="tag__list">
      <li class="tag__list__elm" *ngFor="let tag of tags">
        <div>{{ tag.name }}({{ tag.tag_count }})</div>
        <div *ngIf="tag.editable" class="list-actions">
          <div (click)="editClicked(tag)" class="list-actions__item">
            <mat-icon class="edit-icon" aria-hidden="false" aria-label="編集">edit</mat-icon><span>編集</span>
          </div>
          <div (click)="deleteClicked(tag.id)" class="list-actions__item">
            <mat-icon class="delete-icon" aria-hidden="false" aria-label="削除">delete</mat-icon><span>削除</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="action">
    <h4>タグ登録</h4>
    <mat-divider></mat-divider>
    <form #tagForm="ngForm" (ngSubmit)="onSubmit()">
      <div class="error" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="action__form">
        <mat-form-field class="action__form__input">
          <input matInput matAutosize="false" placeholder="タグを入力" [(ngModel)]="tag.name" name="name">
        </mat-form-field>
        <div class="action__form__buttons">
          <div class="add-button">
            <button mat-raised-button color="primary" type="submit" [disabled]="enableRegister()">登録</button>
          </div>
          <div class="close-button">
            <button mat-raised-button color="accent" (click)="dismissDialog()">閉じる</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>