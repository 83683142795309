import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "./api/api-user.service";
export class UserService {
    constructor(apiUserService) {
        this.apiUserService = apiUserService;
    }
    getMe() {
        return this.apiUserService.getMe();
    }
    getUser(id) {
        return this.apiUserService.getUser(id);
    }
    getFollows() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiUserService.getFollows();
        });
    }
    getFollowers() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiUserService.getFollowers();
        });
    }
    getFollowable(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiUserService.getFollowable(id);
        });
    }
    addFollower(user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiUserService.addFollower(user);
        });
    }
    removeFollower(user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiUserService.removeFollower(user);
        });
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.ApiUserService)); }, token: UserService, providedIn: "root" });
