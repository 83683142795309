import * as tslib_1 from "tslib";
export class GoalService {
    constructor(apiGoalService) {
        this.apiGoalService = apiGoalService;
    }
    create() {
        return {
            id: 0,
            last_name: '',
            first_name: '',
            last_name_kana: '',
            first_name_kana: '',
            gender: '',
            description: '',
            goal: '',
            birthday: '',
            name_jis: '',
            student_number: 0,
            created_at: '',
            updated_at: '',
            attachments: [],
            attributes: [],
            username: '',
            email: '',
            roles: [],
        };
    }
    getGoal(id) {
        return this.apiGoalService.getGoal(id);
    }
    getGoalMine() {
        return this.apiGoalService.getGoalMine();
    }
    updateGoal(user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiGoalService.updateGoal(user);
        });
    }
    deleteAttachment(userId, attachmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apiGoalService.deleteAttachment(userId, attachmentId);
        });
    }
}
