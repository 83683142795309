import * as tslib_1 from "tslib";
import * as FileSaver from 'file-saver';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
export class UtilityService {
    constructor(http) {
        this.http = http;
    }
    fileDownload(url, filename) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(url, { responseType: 'blob' }).pipe(map((value) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                FileSaver.saveAs(value, filename);
                return true;
            })), catchError(this.handleError(`Download ${url}`, false))).toPromise();
        });
    }
    handleError(operation = 'operation', result = null) {
        return (error) => {
            console.error(error);
            console.log(`${operation} failed: ${error && error.message ? error.message : ''}`);
            return of(result);
        };
    }
}
